import React, {useEffect} from 'react';
import {Button} from '@mui/material';
import './text.css';

const Text = ({
                  start,
                  endReading,
                  age,
                  setStart = () => {},
                  setEndReading = () => {},
                  setAge = () => {},
              }) => {
    useEffect(() => {
        if (start && !age) {
            setTimeout(() => setStart(() => false), 2000);
        }

        if (!start && age) {
            setAge(() => '');
        }
    }, [start, age]);

    useEffect(() => {
        setEndReading(() => false);
    }, [age]);

    const handleStart = () => {
        setStart(() => !start);
        if (start) {
            setEndReading(() => false);
        }
    };

    return (
        <div className="text-2">
            <p className="div-9">
                  <span className="text-wrapper-39">
                    Як чумаки по сіль їздили <br />
                  </span>
                <span className="text-wrapper-40">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Про те, що чумаки їздили по сіль до Азовського та Чорного морів,
                    знають усі. Чумацтвом займалися, здебільшого, вільні селяни та козаки. <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Взимку чумаки лагодили спеціальні вози, звані мажами, доглядали
                    волів. Тільки-но тужавіла земля навесні й наставали теплі сонячні дні, вирушали вони в дорогу. Кожен
                    чумак <span className={age === 8 && endReading ? 'highlight' : ''}>брав із собою кілька пар волів, запряжених у мажі. Чумацькою ватагою</span><span className={age === 9 && endReading ? 'highlight' : ''}> керував старший чумак.
                    Він добре мусив знати дорогу й місця</span>, <span className={age === 10 && endReading ? 'highlight' : ''}>де можна було заготовити сіль.
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Чумаки запасалися харчами, переважно пшоном</span> <span className={age === 11 && endReading ? 'highlight' : ''}>та смальцем. З одягу
                    брали ялові чоботи, вовняні свити і пару сорочок та штанів. <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мандрівка</span> на південь України тривала <span className={age === 12 && endReading ? 'highlight' : ''}>близько місяця. Кілька
                    століть в Україні проіснував чумацький промисел.</span>
                  </span>
            </p>
            <div className="element-2">
                5<br />
                10<br />
                15<br />
                19<br />
                22<br />
                25<br />
                28<br />
                32<br />
                35<br />
                39<br />
                43<br />
                48<br />
                54<br />
                58<br />
                61<br />
                65<br />
                71<br />
                75<br />
                78<br />
                81<br />
                86<br />
                91<br />
                94<br />
                97<br />
                100<br />
                105<br />
                107<br />
                108
            </div>
            <img className="line" alt="Line" src="/mobile-img/line-20.svg" />
            <Button
                className="button-3"
                onClick={handleStart}
            >
                {start && age ? (
                    <div className="text-wrapper-button-start">{'СТОП'}</div>
                ) : (start && !age ? (
                        <div className="text-wrapper-button-year">{'ВКАЖІТЬ ВІК'}</div>
                    ) : (
                        <div className="text-wrapper-button-start">{'СТАРТ'}</div>
                    )
                )
                }
            </Button>
        </div>
    );
};

export default Text;