import React from 'react';
import CallOrder from '../CallOrder/CallOrder';

const SmartSchool = () => {
    return (
        <div>
            <p className="p">
            <span className="span">
              Школа <br />
            </span>
                <span className="text-wrapper-smart">розумного</span><br/>
                <span className="text-wrapper-smart-read">читання</span>
                <span className="span">
              {" "}
                    <br />
              Ольги Підуст
            </span>
            </p>
            <p className="text-wrapper-5">Курс швидкочитання для дітей віком 8-12 років</p>
            <img className="vector-2" alt="Vector" src="/mobile-img/vector-40.svg" />
            <CallOrder className={{button: "button", div: "text-wrapper-6"}}/>
        </div>
    );
};

export default SmartSchool;