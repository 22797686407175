import React from "react";
import CallBackOrder from '../callBackOrder/CallBackOrder';
import Contacts from '../../contacts/contacts';
import Logo from '../../logo/Logo';
import PublicOfferAgreement from '../../../../components/PublicOfferAgreement/PublicOfferAgreement';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="overlap-5">
                <Contacts/>
                <div className="overlap-group-wrapper">
                  <Logo/>
                </div>
                <p className="text-wrapper-23">Дізнайтесь більше про наш курс</p>
                <CallBackOrder className={{button: "button", div: "text-wrapper-24"}}/>
                <img className="vector-5" alt="Vector" src="/img/vector-8.svg" />
            </div>
            <div className="public-offer-agreement" >
                <PublicOfferAgreement/>
            </div>
        </footer>
    )
};

export default Footer;