import React, { useEffect, useState, useRef } from 'react';

const Clock = ({
                       start,
                       age,
                       setStart = () => {},
                       setEndReading = () => {},
                   }) => {
    const myRef = useRef(null);
    const [seconds, setSeconds] = useState(0);
    const [iconPosition, setIconPosition] = useState({
        x: 220,
        y: 250,
    });

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (myRef.current) {
    //             const rect = myRef.current.getBoundingClientRect();
    //             const offsetTop = window.scrollY - rect.top + iconPosition.y;
    //             const diffTop = offsetTop;
    //             console.log("Offset Top: ", offsetTop);
    //             setIconPosition((prevState) => ({...prevState, y: diffTop}));
    //         }
    //     };
    //
    //     window.addEventListener("scroll", handleScroll);
    //
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    useEffect(() => {
        let interval;
        if (start && age) {
            interval = setInterval(() => {
                setSeconds(prev => (prev + 1) % 60);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [start, age]);

    useEffect(() => {
        if (start && seconds === 0) {
            setStart(() => false);
            setEndReading(() => true);
        }
    }, [seconds]);

    return (start ? <div
        ref={myRef}
        style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            position: 'absolute',
            left: `${iconPosition.x}px`,
            top: `${iconPosition.y}px`,
            transform: 'translate(50px, 50px)',
            fontSize: '14px',
            backgroundColor: '#b77b0e',
            color: 'white',
            padding: '4px',
            borderRadius: '10px',
            width: '80px',
            zIndex: 10000,
        }}
    >
        {String(seconds).padStart(2, '0')}{':00'}
    </div> : null
);
};

export default Clock;
