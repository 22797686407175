import React, {useEffect, useState} from 'react';
import "./style.css";
import What from './baseComponents/what/What';
import AboutUs from './baseComponents/aboutUs/AboutUs';
import WhatGiveMeCourse from './baseComponents/whatGiveMeCourse/WhatGiveMeCourse';
import AboutCourse from './baseComponents/aboutCourse/AboutCourse';
import MoreAboutCourse from './baseComponents/moreAboutCourse/MoreAboutCourse';
import Reviews from './baseComponents/reviews/Reviews';
import Footer from './baseComponents/footer/Footer';
import AuthorOfCourse from './baseComponents/authorOfCourse/AuthorOfCourse';
import TechniqueReading from './baseComponents/techniqueReading/TechniqueReading';

export const Desktop = () => {
  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    if (isZoomed) {
        window.location.reload();
      setIsZoomed(false);
    }
  }, [isZoomed]);

  return (
      <div className="desktop">
        <div className="div">
          <div className="overlap">
            <AboutCourse/>
            <div className="tutor">
              <div className="overlap-2">
                <div className="overlap-wrapper">
                  <div className="overlap-3">
                    <div className="overlap-4">
                      <div className="overlap-group-2">
                        <div className="rectangle-2"/>
                        <div className="ellipse"/>
                        <div className="ellipse-2"/>
                        <div className="ellipse-3"/>
                      </div>
                      <div className="ellipse-4"/>
                    </div>
                    <div className="ellipse-5"/>
                  </div>
                </div>
                <Reviews/>
                <Footer/>
                <p className="text-wrapper-25">© 2023 - PidustSchool. All Rights Reserved.</p>
              </div>
            </div>
            <TechniqueReading/>
            <AuthorOfCourse/>
            <MoreAboutCourse/>
          </div>
          <div className="group-10"/>
          <AboutUs/>
          <WhatGiveMeCourse/>
          <What setIsZoomed={setIsZoomed}/>
        </div>
      </div>
  );
};
