import React from 'react';
import Phone from './phone';
import Email from './email';
import Telegram from './telegram';
import Instagram from './instagram';
import Facebook from './facebook';

const Contacts = () => {
    return (
        <div className="frame-5" id="contacts">
            <Phone/>
            <Email/>
            <div className="text-wrapper-19">Контакти</div>
            <Facebook className="brand-facebook-footer"/>
            <Instagram className="brand-instagram-footer"/>
            <Telegram className="telegram-footer"/>
        </div>
    );
};

export default Contacts;