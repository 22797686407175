import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {useTranslation} from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    isOpenKey,
    isCloseKey,
    successMessageKey,
    errorMessageKey,
    configServerApiUrl,
    POST,
} from '../../../../constants'; // импорт стилей
import { makeStyles } from '@mui/styles';
import {fetchData} from '../../../../components/functions';

const useStyles = makeStyles({
    success: {
        backgroundColor: 'green',
    },
    error: {
        backgroundColor: 'red',
    },
});

const AddNewsDialog = ({
                           showAddNewsDialog = isCloseKey,
                           setShowAddNewsDialog = () => {},
}) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    const handleSave = () => {
        // Здесь имитация сохранения данных
        if (title && description) {
            const url = `${configServerApiUrl}/news/create`;
            fetchData(url, POST, {title, description})
                .then(response => {
                    return response.json();
                })  // Преобразование ответа в JSON
                .then(data => {
                    if (data.news?.id) {
                        setMessage(`Стаття, що має назву "${data.news?.title}" успішно збережена`);
                        setMessageType(successMessageKey);
                        setShowAddNewsDialog(() => isCloseKey);
                    } else {
                        setMessage('Виникла помилка при збереженні статті');
                        setMessageType(errorMessageKey);
                    }
                })
                .catch((error) => {
                    setMessage(`Мережева помилка - ${error.message}`);
                    setMessageType(errorMessageKey);
                });
        } else {
            setMessage('Помилка при збереженні статті. Будь-ласка, перевірте введені дані.');
            setMessageType(errorMessageKey);
        }
    };

    const handleClose = () => {
        setShowAddNewsDialog(() => isCloseKey);
    };

    useEffect(() => {
        if (showAddNewsDialog === isOpenKey) {
            setIsOpen(true);
        }
        if (showAddNewsDialog === isCloseKey) {
            setIsOpen(false);
        }
    }, [showAddNewsDialog]);

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={setShowAddNewsDialog(() => false)}
            >
                <DialogTitle>{t('news.addNews')}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={t('news.heading')}
                        fullWidth
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <ReactQuill
                        value={description}
                        onChange={(value) => setDescription(value)}
                        modules={{
                            toolbar: [
                                [{ 'header': [1, 2, false] }],
                                ['bold', 'italic', 'underline'],
                                ['image', 'code-block']
                            ]
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">{t('news.cancel')}</Button>
                    <Button onClick={handleSave} color="primary">{t('news.save')}</Button>
                </DialogActions>
            </Dialog>
            <Snackbar //показ сообщений об успехе сохранения статьи
                open={!!message}
                autoHideDuration={3000}
                onClose={() => setMessage('')}
                message={message}
                anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
                ContentProps={{
                    className: messageType === successMessageKey ? classes.success : classes.error
                }}
            >
                <MuiAlert severity={messageType} onClose={() => setMessage('')}>{message}</MuiAlert>
            </Snackbar>
        </div>
    );
};

export default AddNewsDialog;
