import React from "react";
import { createRoot } from 'react-dom/client';

import { Desktop } from "./screens/Desktop";
import ChatWidget from "./components/chat/ChatWidget";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import News from './screens/Desktop/baseComponents/news/News';
import Mobile from './screens/Mobile/Mobile';

const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

const App = () => {
    const Home = () => isMobile ? <Mobile /> : <Desktop />;

    return (
        <Router>
            <I18nextProvider i18n={i18n}>
                <DndProvider backend={HTML5Backend}>
                        <I18nextProvider i18n={i18n}>
                            <DndProvider backend={HTML5Backend}>
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/news" element={<News isMobile={isMobile}/>} />
                                </Routes>
                                <ChatWidget />
                            </DndProvider>
                        </I18nextProvider>
                </DndProvider>
            </I18nextProvider>
        </Router>
    );
};

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App tab="home" />);