import {Button} from '@mui/material';
import React, {useEffect} from 'react';
import './text.css';


const Text = ({
                  start,
                  endReading,
                  age,
                  setStart = () => {},
                  setEndReading = () => {},
                  setAge = () => {},
              }) => {
    useEffect(() => {
        if (start && !age) {
            setTimeout(() => setStart(() => false), 2000);
        }

        if (!start && age) {
            setAge(() => '');
        }
    }, [start, age]);

    useEffect(() => {
        setEndReading(() => false);
    }, [age]);

    const handleStart = () => {
        setStart(() => !start);
        if (start) {
            setEndReading(() => false);
        }
    };

    return (
        <div className="frame-text">
            <p className="div-3">
                  <span className="text-technic-reading-header">
                    Як чумаки по сіль їздили <br />
                  </span>
                <span className="text-wrapper-30">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Про те, що чумаки їздили по сіль до Азовського та Чорного морів,
                    знають усі. Чумацтвом займалися, здебільшого, вільні селяни та козаки. <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Взимку чумаки лагодили спеціальні вози, звані мажами, доглядали
                    волів. Тільки-но тужавіла земля навесні й наставали теплі сонячні дні, вирушали вони в дорогу. Кожен
                    чумак <span className={age === 8 && endReading ? 'highlight' : ''}>брав із собою кілька пар волів, запряжених у мажі. Чумацькою ватагою</span><span className={age === 9 && endReading ? 'highlight' : ''}> керував старший чумак.
                    Він добре мусив знати дорогу й місця</span>, <span className={age === 10 && endReading ? 'highlight' : ''}>де можна було заготовити сіль.
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Чумаки запасалися харчами, переважно пшоном</span> <span className={age === 11 && endReading ? 'highlight' : ''}>та смальцем. З одягу
                    брали ялові чоботи, вовняні свити і пару сорочок та штанів. <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мандрівка</span> на південь України тривала <span className={age === 12 && endReading ? 'highlight' : ''}>близько місяця. Кілька
                    століть в Україні проіснував чумацький промисел.</span>
                  </span>
            </p>
                <div className="element-2">
                    5<br />
                    15
                    <br />
                    22
                    <br />
                    25
                    <br />
                    32
                    <br />
                    40
                    <br />
                    51
                    <br />
                    60
                    <br />
                    71
                    <br />
                    75
                    <br />
                    83
                    <br />
                    94
                    <br />
                    102
                    <br />
                    108
                </div>
                <img className="line" alt="Line" src="/img/line-20.svg" />
            <Button
                className="button-start"
                onClick={handleStart}
            >
                {start && age ? (
                    <div className="text-wrapper-31">{'СТОП'}</div>
                ) : (start && !age ? (
                        <div className="text-wrapper-31-1">{'ВКАЖІТЬ ВІК'}</div>
                    ) : (
                        <div className="text-wrapper-31">{'СТАРТ'}</div>
                    )
                )
                }
            </Button>
        </div>
    );
};

export default Text;