import React from 'react';

const Phone = ({className}) => {
    return (
        <div className={className.div}>
            <img className="image" alt="Image" src="/mobile-img/image.svg" />
            <a
                href="tel:+380987542599"
                className={className.p}
            >(098) 754 25 99</a>
        </div>
    );
};

export default Phone;