import React, {Fragment, useEffect, useState} from 'react';
import Text from './Text';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@mui/styles';
import {Snackbar} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {showMessageAfterRead, successMessageKey} from '../../../../constants';
import MobileSelectionAges from './MobileSelectionAges';
import Clock from './Clock';


const useStyles = makeStyles({
    success: {
        top: '250px',
        backgroundColor: 'green',
        fontSize: '18px',
        fontWeight: 400,
        color: 'red'
    },
});

const Description = () => (
    <Fragment>
        <p className="text-wrapper-42">Перевірте рівень техніки читання вашої дитини</p>
        <div className="frame-20">
            <div className="frame-21">
                <p className="text-wrapper-43">Натисніть «СТАРТ», щоб запусти таймер на 1 хв.</p>
                <div className="frame-22">
                    <div className="text-wrapper-44">1</div>
                </div>
            </div>
            <div className="frame-23">
                <p className="text-wrapper-43">Порахуйте кількість слів, прочитанних дитиною.</p>
                <div className="frame-22">
                    <div className="text-wrapper-44">2</div>
                </div>
            </div>
            <div className="frame-24">
                <div className="text-wrapper-45">Перевірте результат.</div>
                <div className="frame-22">
                    <div className="text-wrapper-44">3</div>
                </div>
            </div>
        </div>
    </Fragment>
);

const TechnicRead = () => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [start, setStart] = useState(false);
    const [endReading, setEndReading] = useState(false);
    const [age, setAge] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (endReading) {
            setMessage(t('reading.message'));
        }
    }, [endReading]);
    return (
        <div className="check-read">
            <Clock
                start={start}
                setStart={setStart}
                age={age}
                setEndReading={setEndReading}
                setAge={setAge}
            />
            <Text
                start={start}
                setStart={setStart}
                age={age}
                endReading={endReading}
                setEndReading={setEndReading}
            />
            <Description/>
            <MobileSelectionAges setAge={setAge}/>
            <div className="message-after-reading">
                <Snackbar //показ сообщений об успехе чтения
                    open={!!message}
                    autoHideDuration={showMessageAfterRead}
                    onClose={() => setMessage('')}
                    message={message}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
                    ContentProps={{
                        className: classes.success
                    }}
                >
                    <MuiAlert severity={successMessageKey} onClose={() => setMessage('')}>{message}</MuiAlert>
                </Snackbar>
            </div>
        </div>
    );
};

export default TechnicRead;