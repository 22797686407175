import React, {useState, useEffect} from "react";
import {Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import {useDrag} from 'react-dnd';
import {ItemTypes} from './ItemTypes';
import "./ChatWidget.css";
import {useTranslation} from 'react-i18next';
import io from 'socket.io-client';
import {chatToken, chatId} from "./chatConfig";
import { DateTime } from 'luxon';
import {socketIOConfig, configServerApiUrl, fullDateFormatWithWeekDay} from '../../constants';
import {validateMobile} from '../functions';

const ChatWidget = () => {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isChatOpen, setIsChatOpen] = useState(true);
    const [dialogChatOpen, setDialogChatOpen] = useState(false);
    const [iconPosition, setIconPosition] = useState({
        x: window.innerWidth - 200,
        y: window.innerHeight - 200,
    });
    const [userName, setUserName] = useState('');
    const [mobile, setMobile] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [userNameEntered, setUserNameEntered] = useState(false);
    const [isUserName, setIsUserName] = useState(false);
    const [socketConnection, setSocketConnection] = useState(io);

    const {t} = useTranslation(); // Use the useTranslation hook

    useEffect(() => {
        const handleScroll = () => {
            setIconPosition({
                x: window.scrollX + window.innerWidth - 200,
                y: window.scrollY + window.innerHeight - 200,
            });
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const [{isDragging}, drag] = useDrag({
        type: ItemTypes.CHAT_ICON,
        item: {type: ItemTypes.CHAT_ICON}, // Add the item type to be dragged
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const offset = monitor.getDifferenceFromInitialOffset();
            if (offset) {
                setIconPosition((prevState) => ({
                    x: prevState.x + offset.x,
                    y: prevState.y + offset.y,
                }));
            }
        },
    });

    const handleNameSubmit = (e) => {
        e.preventDefault();
        if (inputValue.trim() === "") return;
        setDialogChatOpen(true);
        setUserNameEntered(true);
        setUserName(inputValue);
        setInputValue("");
    };

    // установка сокет соединения
    useEffect(() => {
        console.log('Socket_Server_Connect', configServerApiUrl);
        const newSocketConnection = io(`${configServerApiUrl}`, socketIOConfig);
        setSocketConnection(newSocketConnection);
    }, []);

    // Оновлення підписки на подію при зміні messages
    useEffect(() => {
        socketConnection.on('botMessage', (message) => {
            if (mobile === message.mobile) {
                setMessages((prevMessages) => [...prevMessages, message]);
            }
        });

        return () => {
            socketConnection.off('botMessage'); // Відписка від події при виході
        };
    }, [messages]);

    const sendMessageToTelegram = () => {
        const sendMessageToTelegram = (message) => {
            socketConnection.emit('userMessage', message);
            // You can handle the response from the server here if needed
        };
        const messageData = {
            chatToken: chatToken,
            chatId: chatId,
            mobile,
            sender: userName,
            receiver: 'bot',
            text: inputValue,
            date: Date.now()
        };

        setMessages((prevState) => ([...prevState, messageData]));
        sendMessageToTelegram(messageData);
    };

    // Функция для обработки сообщений
    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputValue.trim() === '') return;
        sendMessageToTelegram();
        setInputValue('');
    };

    const getLocalTime = (timestamp) => (
        DateTime.fromMillis(Number(timestamp)).setLocale('ua-Ua').toFormat(fullDateFormatWithWeekDay)
    );

    // Функция для отображения сообщений в чате
    const renderMessages = () => {
        return messages.map((message, index) => (
            <div key={index} className="message">
                {getLocalTime(message.date)} {message.sender}: {message.text}
            </div>
        ));
    };

    // Функция для открытия и закрытия чата
    const toggleChat = () => {
        setIsUserName((prevState) => (!prevState));
        setIsChatOpen((prevState) => (!prevState));

        if (userNameEntered) {
            setDialogChatOpen((prevState) => (!prevState));
        }
    };

    const toggleChatDialog = () => {
        setDialogChatOpen((prevState) => (!prevState));
        setIsUserName((prevState) => (!prevState));
        setIsChatOpen((prevState) => (!prevState));
    };

    return (
        <>
            {isChatOpen && (// Компонент круга с рисунком Чата
                <div
                    className="chat-icon"
                    onClick={toggleChat}
                    ref={drag}
                    style={{
                        opacity: isDragging ? 0.5 : 1,
                        backgroundColor: "whitesmoke",
                        width: "100px",
                        height: "100px",
                        cursor: "grab",
                        position: "absolute",
                        left: `${iconPosition.x}px`,
                        top: `${iconPosition.y}px`,
                    }}
                >
                    <div
                        className="chat-img"
                    />
                    <div
                        style={{
                            position: "absolute",
                            width: "100%",
                            textAlign: "center",
                            bottom: "15px",
                            fontSize: "12px",
                            fontWeight: "bold",
                        }}
                    >
                        {t('chatWidget.label')}
                    </div>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsChatOpen(false);
                            setIsUserName(false);
                            setDialogChatOpen(false);
                        }}
                        style={{
                            position: "absolute",
                            top: "-10px",
                            right: "-10px",
                            background: "#ffffff",
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                        }}
                    >
                        <Close/>
                    </IconButton>
                </div>
            )}
            {isUserName && !userNameEntered && (// Ввод имени пользователя
                    <Dialog
                        open={isUserName}
                        onClose={toggleChat}
                        className="chat-widget-container"
                    >
                        <DialogTitle>
                            {t('chatWidget.userName')}
                        </DialogTitle>
                        <DialogActions>
                            <form className="chat-input-form">
                                <TextField
                                    type="text"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    placeholder={t('chatWidget.myName')}
                                    autoFocus
                                    margin="dense"
                                    label="Ім'я"
                                />
                                <TextField
                                    type="text"
                                    value={mobile}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setMobile(value);
                                        if (!validateMobile(value)) {
                                            setMobileError('Некоректний номер телефону');
                                        } else {
                                            setMobileError('');
                                        }
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    placeholder={t('chatWidget.mobile')}
                                    error={!!mobileError}
                                    helperText={mobileError}
                                    autoFocus
                                    margin="dense"
                                    label="Телефон"
                                />
                                {!!mobile && !mobileError && <Button onClick={handleNameSubmit}
                                    variant="contained"
                                    color="primary"
                                    className="chat-submit-button"
                                >
                                    {t('chatWidget.ok')}
                                </Button>}
                            </form>
                        </DialogActions>
                    </Dialog>
            )}
            {dialogChatOpen && userNameEntered && (// Основной контейнер чата
                <div>
                    <Dialog
                        open={dialogChatOpen}
                        onClose={toggleChatDialog}
                        className="chat-widget-container"
                    >
                        <DialogTitle>{t('chatWidget.title')}</DialogTitle>
                        <DialogContent dividers>
                            <div className="chat-messages">{renderMessages()}</div>
                        </DialogContent>
                        <DialogActions>
                            <form onSubmit={handleSubmit} className="chat-input-form">
                                <TextField
                                    type="text"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    placeholder={t('chatWidget.placeholder')}
                                    autoFocus
                                    margin="dense"
                                    label="Повідомлення..."
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="chat-submit-button"
                                >
                                    {t('chatWidget.sendButton')}
                                </Button>
                            </form>
                        </DialogActions>
                    </Dialog>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setDialogChatOpen(false);
                        }}
                        style={{
                            position: "fixed",
                            top: "-10px",
                            right: "-10px",
                            background: "#fff",
                            borderRadius: "50%",
                        }}
                    >
                        <Close/>
                    </IconButton>
                </div>
            )}
        </>
    );
};

export default ChatWidget;
