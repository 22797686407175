import React, {useState} from 'react';
import Main from './Main/Main';

const Mobile = () => {
  const [menuOpen, setMenuOpen] = useState(false);

    return <Main
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
            />
};

export default Mobile;
