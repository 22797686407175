import CallOrder from '../CallOrder/CallOrder';
import React from 'react';
import Contacts from '../Contacts/Contacts';
import PublicOfferAgreement from '../../../../components/PublicOfferAgreement/PublicOfferAgreement';

const Footer = () => {
    return (
        <footer className="footer">
            <Contacts mode="footer"/>
            <div className="frame-36">
                <p className="text-wrapper-51">Дізнайтесь більше про наш курс</p>
                <CallOrder className={{button: "button-4", div: "text-wrapper-52"}}/>
            </div>
            <p className="text-wrapper-53">© 2023 - PidustSchool. All Rights Reserved.</p>
            <img className="vector-3" alt="Vector" src="/mobile-img/vector-8.svg" />
            <div style={{marginTop: '90px', marginLeft: '70px'}}>
                <PublicOfferAgreement/>
            </div>
        </footer>
    );
};

export default Footer;