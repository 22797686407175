import React, {Fragment} from 'react';

const TextPublicOffer = () => {
    return (
        <Fragment>
            <h1><p style={{fontWeight: '800'}}>Публічна оферта</p></h1> <br/>
            <p style={{fontWeight: 'bold'}}>1. Загальні положення</p><br/>

            1.1. Даний документ, відповідно до Цивільного Кодексу України, є офіційною пропозицією (публічною офертою),
            далі за текстом – «Оферта», «Школа розумного читання Ольги Підуст», далі по тексту – «Виконавець»,
            і містить всі істотні умови, необхідні для надання консультаційних послуг з питань інформатизації,
            далі по тексту – «Послуги».<br/>
            1.1.1. Під «Школа розумного читання Ольги Підуст» розуміється, що послуги надає Фізична особа-підприємець
            Підуст О.О. відповідно до умов даної Оферти та додатків до неї, які є її невід’ємною частиною.<br/>

            1.2. Відповідно до статей 641, 642 Цивільного Кодексу України (ЦК України) в разі прийняття викладених нижче
            умов і оплати Послуг юридична або фізична особа, яка провадить акцепт цієї оферти стає «Замовником»
            (акцепт оферти рівносильний укладенню договору на умовах, викладених в Оферті).<br/>

            1.3. Беручи до уваги, що Виконавець відповідно до статті 633 ЦК України надає Послуги будь-якій юридичній
            особі, фізичній особі, яка здійснює підприємницьку діяльність без створення юридичної особи,
            або фізичній особі, а Замовник після акцепту оферти стає користувачем послуг Виконавця, Сторони погодилися з
            безумовним і беззастережним прийняттям умов цієї Оферти і зобов’язуються неухильно їх дотримуватися.<br/>

            1.4. У зв’язку з вищевикладеним, уважно прочитайте текст даної Оферти, і якщо ви не згодні з будь-яким
            пунктом Оферти, Виконавець пропонує вам відмовитися від використання Послуг.<br/>

            1.5. Акцептом оферти є запис на обраний курс консультаційних послуг з розвитку читацької грамотності
            Замовником і подальша оплата послуг Виконавця.<br/>

            1.6. Зміна або доповнення цієї Оферти можливо виключно при укладанні письмового Договору.
            При цьому Оферта є невід’ємною частиною відповідного письмового Договору.<br/>

            1.7. Запис на курс консультаційних послуг і подальша оплата послуг Виконавця, як спосіб прийняття умов
            (згоди з умовами) Оферти означає згоду Замовника (в разі, якщо Замовник є фізичною особою)
            або фізичної особи – представника Замовника (далі за текстом цього пункту Оферти – суб’єкт персональних даних),
            на обробку Виконавцю своїх персональних даних з метою можливості виконання умов цього Договору, можливості
            проведення взаєморозрахунків, а також для отримання рахунків, актів та інших документів.
            Дозвіл на обробку персональних даних діє протягом усього терміну дії оферти, а також протягом наступних
            п’яти років після закінчення її дії. Знищення персональних даних є підставою для розірвання Оферти
            і виконується виключно на підставі письмової (паперової) заяви Замовника. В такому випадку,
            Оферта розривається з дати, зазначеної у відповідному повідомленні Виконавця.
            Крім цього, укладенням цієї Оферти, суб’єкт персональних даних підтверджує,
            що він повідомлений (без додаткового повідомлення) про права, встановлені Законом України
            «Про захист персональних даних», про цілі збору даних, а також про те, що його персональні дані передаються
            з метою можливості виконання умов цієї Оферти, можливості проведення взаєморозрахунків,
            а також для отримання рахунків, актів та інших документів. Суб’єкт персональних даних також погоджується
            з тим, що Виконавець має право надавати доступ та передавати його персональні дані третім особам
            без будь-яких додаткових повідомлень суб’єкта персональних даних, не змінюючи при цьому мету обробки
            персональних даних. Обсяг прав суб’єкта персональних даних відповідно до Закону України «Про захист
            персональних даних» йому відомий і зрозумілий.<br/><br/>

            <p style={{fontWeight: 'bold'}}>2. Предмет оферти</p><br/>

            2.1. Предметом цієї Оферти є оплатне надання Замовнику консультаційних Послуг з розвитку читацької грамотності.<br/>

            2.2. Виконавець надає послуги в наступному вигляді:<br/>

            2.2.1. Дистанційні заходи з використанням трансляції в мережі Інтернет
            (вебінари, трансляції майстер-класів, семінарів, тематичних курсів і т.п.)<br/>

            2.2.2. Записи заходів, що надаються в електронному вигляді за допомогою мережі Інтернет.<br/>

            2.2.3. Навчальні курси, що надаються в електронному вигляді за допомогою мережі Інтернет.<br/>

            2.3. Виконавець має право в будь-який момент змінювати Календар заходів і умови публічної оферти
            в односторонньому порядку без попереднього узгодження з Замовником, забезпечуючи при цьому публікацію
            змінених умов на Офіційному Веб-сайті Виконавця https://pidustschool.com.ua/  або в офіційних групах
            Виконавця в соціальних мережах Facebook (https://www.facebook.com/profile.php?id=100005769438352),
            в Instagram (https://www.instagram.com/reading_course_olga_pidust/)
            або (https://instagram.com/olgapidust?igshid=OGQ5ZDc2ODk2ZA== )(далі — Веб-сайтах),
            не пізніше ніж за один день до їх введення в дію.<br/>

            2.4. Оферта є офіційним документом, має відповідну юридичну силу і публікується на Веб-сайтах.<br/>

            2.5. Замовник погоджується, що всі можливі суперечки щодо даної Оферти будуть вирішуватися
            відповідно до Законодавства України.<br/><br/>

            <p style={{fontWeight: 'bold'}}>3. Права і обов’язки Сторін</p><br/>

            3.1. Обов’язки Виконавця:<br/>

            3.1.1. Своєчасно надавати Послуги Замовнику з дотриманням умов цієї Оферти;<br/>

            3.1.2. Не поширювати отриману від Замовника або інших джерел інформацію, що виходить за рамки умов цієї Оферти,
            в ході реалізації Виконавцем своїх зобов’язань за цією Офертою, згідно чинного законодавства України;<br/>

            3.1.3. Негайно інформувати Замовника про невідповідність наданих ним матеріалів вимогам Виконавця
            або чинного законодавства України. При цьому термін виконання Послуг може переноситися на відповідний термін,
            протягом якого Замовник надавав відповідні виправлення Виконавцю;<br/>

            3.1.4. Після виконання послуг, за запитом Замовника, протягом 3 (трьох) робочих днів,
            надати Замовнику Акт виконаних робіт.<br/>

            3.2. Обов’язки Замовника:<br/>

            3.2.1. Своєчасно і достовірно, а також, в повному обсязі надавати Виконавцю всю необхідну інформацію,
            пов’язану з наданням Послуг;<br/>

            3.2.2. Негайно передавати Виконавцю всі свої побажання по предмету даної Оферти в письмовому вигляді;<br/>

            3.2.3. Своєчасно і в повному об’ємі оплачувати Послуги;<br/>

            3.2.4. Підписати Акт виконаних робіт протягом 10 (десяти) календарних днів з моменту його отримання;<br/>
            3.2.5. В разі, якщо Акт виконаних робіт не вимагається Замовником протягом 10 (десяти) календарних днів після
            відповідного повідомлення Виконавцем про завершення виконання послуг або не підписаний після його надання
            протягом 10 (десяти) календарних днів, при цьому, Виконавцем не була отримана мотивовану відмову
            від прийняття послуг (підписання Акту), то Акт виконаних робіт вважається підписаним,
            а виконані Послуги надані належним чином і в повному обсязі без будь-яких зауважень і претензій зі Сторони Замовника.
            Правові наслідки такого факту прирівнюються до правових наслідків підписання Акту виконаних робіт.<br/>

            3.3. Замовник має право інформувати Виконавця про виявлені недоліки у наданні Послуг.<br/>

            3.4. Виконавець має право своєчасно отримувати інформацію, необхідну для надання Послуг.<br/>

            3.5. Виконавець має право призупинити виконання Послуг з одночасним повідомленням Замовника в разі
            недотримання Замовником будь-яких домовленостей і зобов’язань, обумовлених при укладанні цієї Оферти
            (або будь-якого іншого Договору з Замовником) або в процесі її виконання, як в письмовій так і в усній формі;
            створення Замовником по будь-яким причинам умов, як технічного так і економічного характеру,
            що перешкоджають нормальному завершенню даної Оферти; ненадання Замовником будь-яких матеріалів,
            обумовлених при укладанні даної Оферти; несвоєчасної або неповної оплати Послуг.
            У перерахованих вище пунктах, при неусунення причин призупинення дії оферти, договір-оферти може
            бути розірваний (з вини Замовника). Результати виконаних робіт в цьому випадку Замовнику не передаються.
            Повторне відновлення Послуг проводиться тільки після усунення Замовником причин, що призвели до призупинення Послуг.
            Термін виконання Послуг автоматично продовжується на час простою.<br/><br/>

            <p style={{fontWeight: 'bold'}}>4. Відповідальність сторін</p><br/>

            4.1. Виконавець за жодних обставин не несе відповідальності за:<br/>
            •	а) будь-які дії / бездіяльність, що є прямим або непрямим результатом дії / бездіяльності будь-яких третіх сторін;<br/>
            •	б) будь-які непрямі збитки та / або упущену вигоду Замовника та / або третіх сторін незалежно від того,
            міг Виконавець передбачити можливість таких збитків чи ні;<br/>
            •	в) використання (неможливість використання) і які б то не було наслідки використання
            (неможливості використання) Замовником обраної ним форми оплати Послуг за цією Офертою.<br/>

            4.2. У разі невиконання або неналежного виконання однією із Сторін своїх зобов’язань згідно з цією Оферті,
            Сторони несуть відповідальність згідно з чинним законодавством України.<br/>

            4.3. У разі виникнення претензій, суперечок, застосування штрафних санкцій з боку третіх осіб і / або
            контролюючих органів, пов’язаних з виконанням Послуг відповідно до даної Оферти, Замовник розглядає
            і вирішує ці питання самостійно, без залучення Виконавця, а також зобов’язується невідкладно компенсувати
            Виконавцю в повному обсязі будь-які збитки, витрати, включаючи штрафні санкції, спричинені порушенням
            вищезазначених прав, гарантій і т.д.<br/>

            4.4. Виконавець не несе відповідальності за дії (або бездіяльність),
            а також прийняті рішення державних та інших органів, пов’язані з виконанням цієї Оферти.<br/>

            4.5. Виконавець не несе відповідальності за збільшення терміну виконання Послуг,
            у разі неоперативності роботи державних та інших органів, пов’язаних з виконанням цієї Оферти.<br/>

            4.6. Замовник несе повну відповідальність за дотримання всіх вимог законодавства України
            та норм міжнародного права, включаючи відповідальність за зміст Заявки.<br/>

            4.7. У разі порушення Замовником умов Пропоновані можливості Виконавець має право припинити надання Послуг
            до моменту усунення Замовником допущених порушень та / або розірвати Оферту з направленням відповідного
            повідомлення Замовникові за адресою електронної пошти Замовника, вказану при напрямку Заявки.<br/><br/>

            <p style={{fontWeight: 'bold'}}>5. Умови та порядок надання Послуг</p><br/>

            5.1. Консультаційні послуги надаються в повному обсязі за умови їх 100% (сто відсотків) оплати Замовником.<br/>

            5.2. Деякі курси і заходи, визначені Виконавцем, можуть мати тестовий (неоплачуваний) період,
            тривалість якого становить не більше 7 (семи) днів. Після закінчення зазначеного періоду,
            за умови продовження участі в курсі або заході, участь в визначених курсах і заходах є платним.
            У тому числі до оплати підлягає тестовий період.<br/>

            5.3. Факт придбання консультаційних послуг є беззаперечним прийняттям Замовником даного Договору-оферти.
            Замовник, який скористався послугами Виконавця, розглядається як особа, яка вступила з Виконавцем в договірні відносини.<br/>

            5.4. Оплата курсів / заходів проводиться за відповідним посиланням на сторінці курсу / тренінгу.
            Під час оплати Замовник вказує в спеціальній формі на сторінці сайту свої контактні дані.
            Замовник повністю несе відповідальність за правильність і достовірність вказаних контактних даних,
            оскільки саме за вказаними контактними даними проводитиметься обмін інформацією.
            Після здійснення оплати за цим договором без виставлення рахунку, Замовник отримує автоматичне підтвердження на Веб-сайтах.<br/>

            5.5. Посилання на проходження платних курсів / дистанційних заходів надсилається Замовнику за адресою електронної пошти,
            вказаною при оформленні Заявки, тільки після надходження 100% коштів Замовника на розрахунковий рахунок Виконавця.
            У разі, якщо Замовник оплатив і не отримав посилання для проходження платного курсу / дистанційного заходи
            через 3 робочі дні після оплати – необхідно зв’язатися зі Службою підтримки
            «Школи розумного читання Ольги Підуст» за адресою електронної пошти olga@pidustschool.com.ua
            (Далі – Служба підтримки) надавши копію квитанції про оплату.<br/>

            5.6. Інформація про участь в очному заході висилається Замовникові за адресою електронної пошти,
            вказаною Замовником при оформленні Заявки протягом трьох днів після надходження 100% коштів Замовника на
            розрахунковий рахунок Виконавця. Надалі вся інформація про підготовку та проведення очного заходи також
            направляється Замовнику на електронну адресу, вказану при оформленні Заявки, а також розміщується на сайті з
            інформацією про захід. У разі, якщо Замовник оплатив і не отримав інформацію про участь в очному заході
            через 3 робочі дні після оплати – необхідно зв’язатися зі Службою підтримки, надавши копію квитанції про оплату.<br/>

            5.7. При належному виконанні Виконавцем своїх зобов’язань за цією Офертою Замовник приймає виконані роботи,
            в тому числі і по Акту виконаних робіт, який надсилається Замовнику Виконавцем на його вимогу
            з урахуванням умов 3.1.4, 3.2.5 Пропоновані можливості.
            Замовник відповідно до п. 3.2.4 підписує отриманий Акт виконаних робіт,
            а в разі виявлення недоліків при прийнятті Замовником наданих Виконавцем Послуг,
            зобов’язується в той же термін надати мотивовану відмову від прийняття робіт (послуг) із зазначенням недоліків
            та строком їх усунення та надсилає його Виконавцю. Виконавець зобов’язаний усунути зазначені у відмові недоліки
            в розумний строк і надати новий Акт виконаних робіт Замовнику.
            У разі непідписання Замовником нового Акту виконаних робіт та непред’явлення вмотивованої відмови
            від виконаної роботи в 3- (трьох) денний термін, новий Акт вважається підписаним, а робота прийнятою.
            Якщо Акти виконаних робіт не оформлюється, робота вважається прийнятою, якщо Замовник в 10- (десяти) денний
            термін не пред’явить свої письмові претензії Виконавцю. Відсутність вимоги про повернення грошових коштів
            відповідно до пункту 6.7. цього договору, означає, що послуги надані в термін і належної якості.<br/>

            5.8. Виконавець залишає за собою право відсторонити Замовника від заходу без права на повернення
            грошових коштів у разі порушення правил поведінки на заході, а саме: розпалювання міжнаціональних конфліктів,
            відволікання учасників від теми занять, спам, розміщення реклами, нецензурні висловлювання, хамство,
            образи ведучого, службового персоналу або учасників.<br/>

            5.9. Виконавець залишає за собою право здійснювати запис проведених заходів.<br/>

            5.10. Оплата Послуг здійснюється Замовником одним з пропонованих на веб-сайті способом.
            Вибір і використання способу / форми оплати Послуг здійснюється Замовником на власний розсуд із
            запропонованих варінтів. Безпека, конфіденційність, а також інші умови використання обраних
            Замовником способу / форми оплати виходять за рамки даної Оферти і регулюються угодами (договорами)
            між Замовником та відповідними організаціями.<br/>

            5.11. На визначенні Виконавцем дистанційні курси і заходи, за умови письмового звернення Замовника в
            Службу Підтримки, діє академічна відпустка (так званий – період заморозки).
            Максимальний період академічної відпустки становить 30 календарних днів в півріччі.
            У період академічної відпустки невикористані кошти заморожуються на балансі Замовника.
            Для активації академічної відпустки Замовник повинен звернутися в Службу підтримки з проханням надання
            академічної відпустки. Активація академічної відпустки не може бути здійснена минулою датою,
            а також тривати менше 5 календарних днів. Активація академічної відпустки відбувається або з дати отримання
            Службою підтримки прохання про надання академічної відпустки, або з дати, яка вказана в майбутньому часі.
            Після закінчення академічної відпустки, яка рівна 30 дням в півріччі, відбувається активація раніше
            сплаченого навчального періоду і списання грошових коштів з балансу Замовника.
            Якщо Замовник після академічної відпустки не скористався активацією раніше сплаченого навчального періоду,
            Виконавець має право вважати навчальний період повністю використаним Замовником і
            привласнити Замовнику статус “Відсів”. Поновлення послуг Замовнику надається на підставі нової заявки через
            ресурси Виконавця або листи до Служби підтримки.
            При цьому, застосовуються умови і тарифи, що діють на момент поновлення послуг.<br/>

            5.12. Грошові кошти, сплачені Замовником, але не використані ним в рамках обраної послуги,
            знаходяться на балансі Замовника, які він може використовувати для оплати будь-яких інших послуг Виконавця
            протягом одного місяця, відповідно до пункту 2.2.
            Якщо Замовник протягом одного місяця не скористався раніше оплаченими грошовими коштами,
            Виконавець має право вважати надані послуги наданими належним чином і в повному обсязі.<br/><br/>

            <p style={{fontWeight: 'bold'}}>6. Термін дії, зміни умов і розірвання Оферти</p><br/>

            6.1. Оферта діє з моменту опублікування в мережі Інтернет на веб-сайті, вступає в силу з моменту акцепту
            оферти Замовником і діє до моменту виконання Виконавцем Послуг згідно даної Оферти.<br/>

            6.2. Всі зміни і доповнення до цієї Оферті мають юридичну силу, лише в разі складання письмового документа,
            завіреного підписами і печатками уповноважених представників Сторін.<br/>

            6.3. Оферта може бути розірвана за згодою Сторін.<br/>

            6.4. У разі істотного порушення однією із Сторін умов Оферти інша Сторона має право на одностороннє
            розірвання Оферти, про що письмово повідомляє Сторону, яка порушила умови Договору,
            не менше ніж за 14 календарних днів.<br/>

            6.5. При розірванні Оферти або припинення його терміну дії вся наявна в електронному вигляді інформація
            Замовника зберігається у Виконавця протягом терміну, регламентованого внутрішніми документами.<br/>

            6.6. Справжня оферта може бути змінена Виконавцем в односторонньому порядку для всіх своїх клієнтів
            з одночасною публікацією нової версії на веб-сайті, а також з повідомленням всіх клієнтів Виконавця за
            допомогою електронної пошти. У разі незгоди Замовника з внесеними змінами, укладається окрема письмова угода,
            в якій будуть врегульовані спірні питання.<br/>

            6.7. Замовник має право відмовитися від надання оплачених консультаційних послуг і вимагати повернення
            сплачених грошових коштів в наступних випадках:<br/>

            6.7.1. У разі відмови від участі в очному заході (курсі) не менше, ніж за 14 днів до початку оплаченого
            заходу (курсу). В окремих випадках повернення оплати проводиться за умови повідомлення про відмову в його
            проходженні не менше, ніж за 30 днів до початку заходу, інформація про що дається додатково в умовах оплати
            відповідного заходу.<br/>

            6.7.2. У разі відмови Замовника від проходження дистанційного відео-курсу, до моменту надання доступу до
            оплаченого дистанційного курсу. Якщо заявка отримана пізніше, від моменту надання доступу до дистанційного курсу,
            авансовий платіж не повертається.<br/>

            6.7.3. У разі відмови Замовника під час проходження  курсу онлайн-навчання, авансовий платіж повертається частково,
            в розмірі пропорційному неопрацьованим дням курсу до загальної тривалості онлайн-курсу.
            Дата припинення онлайн-курсу визначається датою надходження заяви від Замовника, незалежно від кількості
            успішно опрацьованих днів Замовником.<br/>

            6.8. Для отримання повернення Замовник повинен надіслати у встановлений термін заявку на повернення в Службу підтримки.
            Оригінал заяви, визначеної форми, заповненої та підписаної Замовником, надсилається Виконавцю.
            Оплата здійснюється на рахунок, з якого Виконавець отримав оплату.
            Повернення грошей здійснюється протягом 3 (трьох) банківських днів з моменту отримання Виконавцем оригіналу заяви на повернення.
            При поверненні коштів оплата комісій банку та вартості транзакції, якщо платіж здійснювався за допомогою
            електронних систем прийому платежів, здійснюється за рахунок  Замовника.<br/><br/>

            <p style={{fontWeight: 'bold'}}>7. Гарантії</p><br/>

            7.1. За винятком гарантій, прямо зазначених в тексті Оферти, Виконавець не надає ніяких інших прямих
            або непрямих гарантій по даній Оферті.<br/>

            7.2. Погоджуючись з умовами (приймаючи умови) цієї Оферти, Замовник запевняє Виконавця і гарантує Виконавцю, що:<br/>

            7.2.1. У разі надання Замовником Виконавцю недостовірних даних або матеріалів,
            Замовник несе повну відповідальність (в тому числі майнову) за будь-які негативні наслідки,
            пов’язані з наданням Виконавцем Послуг за цією Оферті;<br/>

            7.2.2. Замовник укладає Оферту добровільно, при цьому замовник підтверджує:<br/>
            •	а) повністю ознайомився з умовами оферти,<br/>
            •	б) повністю розуміє предмет Оферти,<br/>
            •	в) повністю розуміє значення і наслідки своїх дій щодо укладення та виконання оферти.<br/>

            7.2.3. Замовник має всі права і повноваження, необхідні для укладення та виконання оферти Замовником.<br/>

            7.3. Вся надана у зв’язку з виконанням Оферти інформація є конфіденційною.
            Сторони зобов’язуються не розголошувати її третім особам і не використовувати в будь-яких цілях, не передбачених офертою.<br/>

            7.4. Дія гарантій, зазначених в Оферті, поширюється на необмежений період часу і не закінчується після завершення дії цієї Оферти.<br/><br/>

            <p style={{fontWeight: 'bold'}}>8. Форс-мажор</p><br/>

            8.1. Виконавець і Замовник звільняються від невиконання або неналежного виконання своїх обов’язків за поточною Офертою,
            якщо таке невиконання або неналежне виконання є наслідком дії обставин непереборної сили (форс-мажору),
            які виникли після укладення поточної Оферти, мають незвичайний характер,
            і що виконавець і Замовник не могли ні передбачити, ні подолати прийнятими засобами.
            До таких обставин належать: повені, пожежа, землетрус, і інші природні явища, а також війна, військові дії,
            страйки, акти або дії компетентних органів, органів державної влади та будь-які інші подібні обставини,
            які знаходяться поза контролем Сторін.<br/>

            8.2. Якщо обставини форс-мажору тривають більше 3-х місяців, кожна зі Сторін має право припинити дію цієї Оферти.<br/><br/>

            <p style={{fontWeight: 'bold'}}>9. Інші умови</p><br/>

            9.1. Оферта, її укладання та виконання регулюється відповідно до чинного законодавства України.
            Всі питання, неврегульовані Офертою або врегульовані не повністю, регулюються відповідно до матеріального права України.
            У разі розбіжностей між Замовником та Виконавцем щодо Оферти, які не можуть бути вирішені шляхом переговорів Сторін,
            врегульовуються в порядку, передбаченому чинним законодавством України.<br/>

            9.2. Оферта являє собою повну домовленість між Виконавцем і Замовником.
            Виконавець не приймає на себе жодних умов і зобов’язань щодо предмета Оферти,
            за винятком зазначених у Оферті, і підтвердженої Заявки, якими регулюється виконання оферти,
            за винятком випадків, коли такі умови або зобов’язання зафіксовані в письмовому вигляді і підписані Виконавцем і Замовником.
            У разі, якщо будь-які умови Додатків або Заявки суперечать умовам Оферти, положення Оферти будуть переважати.<br/>

            9.3. Якщо будь-яка з умов Оферти визнана недійсною або незаконною, або не може вступити в силу
            у відповідності з чинним законодавством, такий стан має бути виділений з Оферти і замінено новим положенням,
            максимально відповідає початковим намірам, які містилися в Оферті, при цьому інші
            положення Оферти не змінюються і залишаються в силі.<br/><br/>

            <p style={{fontWeight: 'bold'}}>10. Реквізити Виконавців</p><br/>

            <p style={{fontWeight: 'bold'}}>Фізична особа – підприємець Підуст Ольга Олександрівна</p><br/>
            Aдреса: 51925 , Україна, м. Кам’янське, Дніпропетровська обл.<br/>
            вул. Короленківська, 54 кв.27<br/>
            Банківські реквізити:<br/>
            IBAN: UA583220010000026004330143456<br/>
            ІПН/ЄДРПОУ 3109012502<br/>
            АТ УНІВЕРСАЛ БАНК<br/>
            МФО 322001<br/>
            ЄДРПОУ Банку:  21133352<br/>
            Офіційний сайт: <a href = 'https://pidustschool.com.ua/'>https://pidustschool.com.ua</a><br/>
            e-mail: olga@pidustschool.com.ua<br/><br/>
        </Fragment>

    );
};

export default TextPublicOffer;