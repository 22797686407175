import React from 'react';

const WhatIsLearning = () => {
    return (
        <div className="about-2">
            <p className="div-3">
                <span className="span">Чому я </span>
                <span className="text-wrapper-4">навчусь</span>
                <span className="span"> у школі розумного читання</span>
            </p>
            <div className="frame-9">
                <p className="div-4">
                    <span className="text-wrapper-11">Використовувати інтонації</span>
                    <span className="text-wrapper-12">
                , робити голос сильним
                <br /> та оксамитовим
              </span>
                </p>
                <div className="img-wrapper">
                    <img className="tilda-icons" alt="Tilda icons" src="/mobile-img/tilda-icons-11mu-melomane.svg" />
                </div>
            </div>
            <div className="frame-10">
                <p className="div-5">
                    <span className="span">&nbsp;</span>
                    <span className="text-wrapper-13">Опрацьовувати</span>
                    <span className="span">
                {" "}
                        велику кількість матеріалу <br />
                за невеликий проміжок часу
              </span>
                </p>
                <div className="img-wrapper">
                    <img className="tilda-icons-book" alt="Tilda icons book" src="/mobile-img/tilda-icons-1ed-book.svg" />
                </div>
            </div>
            <div className="frame-11">
                <p className="div-4">
                    <span className="text-wrapper-11">Говорити легко</span>
                    <span className="text-wrapper-12">
                , виразно <br />і переконливо
              </span>
                </p>
                <div className="img-wrapper">
                    <img className="tilda-icons" alt="Tilda icons" src="/mobile-img/tilda-icons-1ed-speaker.svg" />
                </div>
            </div>
            <div className="frame-12">
                <p className="div-6">
                    <span className="text-wrapper-11">Навчиться аналізувати</span>
                    <span className="text-wrapper-12"> прочитаний текст, робити узагальнення, висновки</span>
                </p>
                <div className="tilda-icons-wrapper">
                    <img className="tilda-icons" alt="Tilda icons" src="/mobile-img/tilda-icons-1ed-brain.svg" />
                </div>
            </div>
            <div className="frame-logic">
                <p className="div-6">
                    <span className="text-wrapper-11">Логічно мислити</span>
                </p>
                <div className="tilda-icons-wrapper-logic">
                    <img className="tilda-icons" alt="Tilda icons" src="/mobile-img/quest_logic.svg" />
                </div>
            </div>
            <div className="frame-success">
                <p className="div-6">
                    <span className="text-wrapper-11">Бути успішною людиною</span>
                </p>
                <div className="tilda-icons-wrapper">
                    <img className="tilda-icons" alt="Tilda icons" src="/mobile-img/cup.svg" />
                </div>
            </div>
        </div>
    );
};

export default WhatIsLearning;