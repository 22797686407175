import React from "react";


const Reviews = () => {
  return (
      <div className="view-4">
        <div className="group-2">
          <div className="text-wrapper-14">Марина Анатольевна</div>
          <div className="div-2">
            <div className="div-2">
              <div className="group-wrapper">
                <div className="group-3">
                  <p className="text-wrapper-15">
                    Дуже класні курси! Мій син пройшов ці курси – результати вражаючі навіть за короткий
                    проміжок часу!
                  </p>
                  <img className="vector" alt="Vector" src="/img/vector-3.png" />
                  <img className="vector-2" alt="Vector" src="/img/vector-3.png" />
                </div>
              </div>
            </div>
            <img className="image" alt="Image" src="/img/2x.png" />
          </div>
          <div className="hr-dots">
            <div className="ellipse-6" />
            <div className="ellipse-7" />
            <div className="ellipse-8" />
          </div>
        </div>
        <div className="text-wrapper-16">Відгуки</div>
      </div>
  );
};

export default Reviews;