
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DateTime } from 'luxon';
import TextPublicOffer from './TextPublicOffer';

const PublicOfferAgreement = () => {
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (agree) => {
        if (agree && checked) {
            // Действия при согласии пользователя
        } else {
            // Действия при отказе
        }
        setOpen(false);
    };

    return (
        <div>
            <a href="#" onClick={handleClickOpen}>
                Договір публічної оферти
            </a>
            <Dialog
                open={open}
                onClose={() => handleClose(false)}
            >
                <DialogTitle>{"Договір публічної оферти"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <TextPublicOffer/>
                        Дата: {DateTime.fromMillis(Date.now()).toFormat("dd/MM/yyyy")}
                    </DialogContentText>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={(event) => setChecked(event.target.checked)}
                            />
                        }
                        label="Я прочитав(ла) і погоджуюсь з умовами договору"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} color="primary">
                        Відмовляюсь
                    </Button>
                    <Button onClick={() => handleClose(true)} color="primary" disabled={!checked}>
                        Даю згоду
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default PublicOfferAgreement;
