import React, {Fragment, useEffect, useState} from 'react';
import {configServerApiUrl, errorMessageKey, GET, successMessageKey} from '../../../../../constants';
import MuiAlert from '@mui/material/Alert';
import NewsList from '../NewsList';
import {fetchData} from '../../../../../components/functions';

const ReadNews = () => {
    const [allNews, setAllNews] = useState([]);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    useEffect(() => {
        const url = `${configServerApiUrl}/news`;

        fetchData(url, GET)
            .then(response => {
                return response.json();
            })  // Преобразование ответа в JSON
            .then(data => {
                if (data) {
                    setMessage(`${data.message}`);
                    setAllNews(data?.news);
                    setMessageType(successMessageKey);
                } else {
                    setMessage('Виникла помилка при отриманні новин');
                    setMessageType(errorMessageKey);
                }
            })
            .catch((error) => {
                setMessage(`Мережева помилка - ${error.message}`);
                setMessageType(errorMessageKey);
            });
    }, []);


    return (
       <Fragment>
           <MuiAlert severity={messageType} onClose={() => setMessage('')}>{message}</MuiAlert>
           <NewsList allNews={allNews} />
       </Fragment>
    );
};

export default ReadNews;