import {fetchHeaders, GET, POST} from '../../constants';


export const fetchData = (url, method, body) => {
    if (method === GET) {
        return fetch(url, {
            method: GET,
           ...fetchHeaders,
        })
    }

    if (method === POST) {
       return fetch(url, {
           method: POST,
           ...fetchHeaders,
           body: JSON.stringify(body),
       })
    }

}