import React from 'react';
import IconButton from '@mui/material/IconButton';

const Telegram = ({className}) => {
    return (
    <div>
        <IconButton
            component="a"
            href="https://t.me/+evURFqmHX60wMjQy" // сылка на телеграм канал
            className={className}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img className="frame-8" alt="Frame" src="/img/frame-1.svg"/>
        </IconButton>
    </div>
    );
};

export default Telegram;