import React, { useState } from 'react';
import {BasicPhone1} from '../../../../../icons/BasicPhone1';

const CallByPhone = () => {
    const [position, setPosition] = useState(0);
    const maxPosition = 100;

    const handleDrag = (e) => {
        const newPosition = Math.min(e.clientX, maxPosition);
        setPosition(newPosition);
    };

    const handleDragEnd = () => {
        if (position >= maxPosition) {
            window.location.href = 'tel:+380987542599';
        }
        setPosition(0);
    };

    return (
        <div className="frame-34">
            <div className="basic-phone-wrapper">
                <BasicPhone1 className="basic-phone" />
            </div>
            <div className="text-wrapper-59">
                <a href="tel:+380987542599">(098) 754 25 99</a>
            </div>
        </div>
    );
};

export default CallByPhone;
