import {BasicClock} from '../../icons/BasicClock';
import {BasicHappy} from '../../icons/BasicHappy';
import {CalendarCalendar} from '../../icons/CalendarCalendar';
import React from 'react';

const AboutCourse = () => {
    return (
        <div className="course" id="mobile-about-course">
            <p className="div-7">
                <span className="span">Про</span>
                <span className="text-wrapper-4"> курс</span>
            </p>
            <img className="rectangle-4" alt="Rectangle" src="/mobile-img/rectangle-50.png" />
            <div className="frame-13">
                <div className="view-2">
                    <div className="text-wrapper-15">Онлайн курс</div>
                    <div className="group">
                        <div className="div-wrapper">
                            <div className="text-wrapper-16">8-12 років</div>
                        </div>
                    </div>
                    <div className="text-wrapper-17">«Курс Швидкочитання (Advanced)»</div>
                    <div className="frame-14">
                        <BasicClock className="icon-instance-node" />
                        <p className="text-wrapper-18">21 заняття з кваліфікованим педагогом.</p>
                    </div>
                    <div className="frame-15">
                        <BasicHappy className="icon-instance-node" />
                        <p className="text-wrapper-19">У групі по 3-5 учнів або індивідуально.</p>
                    </div>
                    <div className="frame-16">
                        <CalendarCalendar className="icon-instance-node" />
                        <div className="text-wrapper-20">Графік навчання:</div>
                        <div className="text-wrapper-21">2 рази на тиждень.</div>
                    </div>
                    <p className="text-wrapper-22">
                        Для дітей, у яких техніка читання є не нижчою, ніж 60 слів за 1 хвилину.
                    </p>
                    <p className="text-wrapper-23">
                        На цьому курсі дитина зможе підвищити рівень читацьких навичок, зможе опрацьовувати велику кількість
                        матеріалу за невеликий проміжок часу, а головне - розуміти суть, аналізувати будь-який текст,
                        переказувати, робити узагальнення, висновки.
                    </p>
                    <p className="text-wrapper-23-1">
                        Залежно від умінь та віку дитини, курс об’єднується у 2 окремі групи: 8-10 років та 10-12 років.
                    </p>
                </div>
                <div className="overlap-wrapper">
                    <div className="overlap-4">
                        <p className="element">
                            <span className="span">Вартість курсу:</span>
                            <span className="text-wrapper-24">
                    {" "}
                                <br />
                  </span>
                            <span className="text-wrapper-decoration">
                            <span className="text-decoration">4000 грн.</span>
                        </span>
                            <span className="text-wrapper-26">&nbsp;&nbsp;</span>
                            <span className="text-wrapper-25">2300 грн.</span>
                            <span className="text-wrapper-26">&nbsp;</span>
                        </p>
                        <p className="text-wrapper-28">Всього 110 грн. за 1 заняття</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutCourse;