import React, { useState } from 'react';
import MoreAboutCourse from '../moreAboutCourse/MoreAboutCourse';
import { Modal, Box, Button } from '@mui/material';

const CallBackOrder = ({className, isMobileForm = false}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <div>
            <Button className={className?.button} onClick={handleOpenModal}>
                <div className={className?.div}>
                    Замовити зворотній зв’язок
                </div>
            </Button>
            <Modal open={isModalOpen} onClose={handleCloseModal}>
                <Box
                    sx={
                    {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24, p: 4
                    }}
                >
                    <MoreAboutCourse isModalOpen={isModalOpen} isMobileForm={isMobileForm}/>
                    <Button onClick={handleCloseModal}>Вийти</Button>
                </Box>
            </Modal>
        </div>
    );
};

export default CallBackOrder;
