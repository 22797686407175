import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locals/en/translation.json';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: enTranslation,
        },
    },
    lng: 'en', // Set the default language here
    fallbackLng: 'en', // Fallback language if translation is missing for the selected language
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
