import {BasicClock} from '../../icons/BasicClock';
import {BasicHeartOutline} from '../../icons/BasicHeartOutline';
import React from 'react';

const AboutAuthor = () => {
    return (
        <div className="tutour" id="mobileTeachers">
            <p className="div-8">
                <span className="text-wrapper-4">Викладачка та авторка</span>
                <span className="span"> курсу</span>
            </p>
            <div className="view-3">
                <div className="text-wrapper-34">Ольга Підуст</div>
                <p className="text-wrapper-35">Авторка курсу, вчителька початкових класів, психологиня, мама.</p>
                <div className="flexcontainer-2">
                    <p className="span-wrapper">
                <span className="text-wrapper-36">
                  Маю досвід роботи вчителем у Szkoła Podstawowa Warszawa Polska, низку пройдених міжнародних освітніх
                  тренінгів, семінарів, конференцій, форумів.
                  <br/>
                </span>
                    </p>
                    <p className="span-wrapper">
                <span className="text-wrapper-36">
                  До кожного учня я знаходжу індивідуальний підхід, роблю наші заняття максимально змістовними, корисними і цікавими.
                </span>
                    </p>
                </div>
                <div className="frame-17">
                    <div className="frame-18">
                        <p className="text-wrapper-37">Досвід роботи більше 12 років.</p>
                        <BasicClock className="icon-instance-node" />
                    </div>
                    <div className="frame-19">
                        <BasicHeartOutline className="icon-instance-node" />
                        <p className="text-wrapper-38">Вища психологічна та педагогічна освіта.</p>
                    </div>
                </div>
                <img className="rectangle-5" alt="Rectangle" src="/mobile-img/rectangle-46.svg" />
            </div>
        </div>
    );
};

export default AboutAuthor;