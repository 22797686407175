import React, { useEffect, useState } from 'react';
import './Clockwise.css';

const Clockwise = ({
                       start,
                       age,
                       setStart = () => {},
                       setEndReading = () => {},
}) => {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let interval;
        if (start && age) {
            interval = setInterval(() => {
                setSeconds(prev => (prev + 1) % 60);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [start, age]);

    useEffect(() => {
        if (start && seconds === 0) {
            setStart(() => false);
            setEndReading(() => true);
        }
    }, [seconds]);

    const radius = 120;
    const stroke = 10;
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI * 0.75; // скорость движения зарисовки - совпадает с секундой
    const offset = circumference - (seconds / 60) * circumference;

    const secondHandLength = normalizedRadius - 10;
    const secondHandAngle = (seconds / 60) * 360;

    return (
        <div className="clock">
            <svg height={radius * 2} width={radius * 2}>
                {Array.from({ length: 60 }, (_, i) => (
                    <g transform={`rotate(${i * 6} ${radius} ${radius})`} key={i}>
                        {i % 5 === 0 ? (
                            <>
                                <text // цифры вокруг часов
                                    x={radius}
                                    y={stroke * 2}
                                    fill="black"
                                    textAnchor="middle"
                                    alignmentBaseline="central">
                                    {i}
                                </text>
                                <line // большие деления
                                    x1={radius}
                                    y1={stroke * 3}
                                    x2={radius}
                                    y2={stroke * 4}
                                    stroke="black"
                                    strokeWidth="3"
                                />
                            </>
                        ) : (
                            <line // малые деления
                                x1={radius}
                                y1={stroke * 3.5}
                                x2={radius}
                                y2={stroke * 4}
                                stroke="black"
                                strokeWidth="2"
                            />
                        )}
                    </g>
                ))}
                <circle // зарисовка прошедшего времени
                    stroke="orange"
                    fill="transparent"
                    strokeWidth={stroke}
                    strokeDasharray={circumference + ' ' + circumference}
                    style={{ strokeDashoffset: offset}}
                    r={normalizedRadius * 0.75}
                    cx={radius}
                    cy={radius}
                    transform={`rotate(-90 ${radius} ${radius})`}
                />
                <line //стрелка часов
                    x1={radius}
                    y1={radius}
                    x2={radius + secondHandLength * Math.sin((secondHandAngle * Math.PI) / 180)}
                    y2={radius - secondHandLength * Math.cos((secondHandAngle * Math.PI) / 180)}
                    stroke="black"
                    strokeWidth="2"
                />
            </svg>
            <div className="digital">{String(seconds).padStart(2, '0')}{':00'}</div>
        </div>
    );
};

export default Clockwise;
