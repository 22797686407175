import React, {Fragment, useEffect, useState} from 'react';
import Text from './Text';
import SelectionAges from './SelectionAges';
import Clockwise from './Clockwise';
import {showMessageAfterRead, successMessageKey} from '../../../../constants';
import MuiAlert from '@mui/material/Alert';
import {Snackbar} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles({
    success: {
        fontSize: '3.5rem',  // Увеличьте размер шрифта для текста внутри Snackbar
        '& .MuiAlert-message': {
            fontSize: '3.5rem',  // Увеличьте размер шрифта для текста внутри MuiAlert
        }
    },
});

const Description = () => (
    <Fragment>
        <p className="text-wrapper-26">Перевірте рівень техніки читання вашої дитини</p>
        <div className="frame-10">
            <p className="text-wrapper-27">Натисніть «СТАРТ», щоб запусти таймер на 1 хв.</p>
            <div className="frame-11">
                <div className="text-wrapper-28">1</div>
            </div>
        </div>
        <div className="frame-12">
            <p className="text-wrapper-27">Порахуйте кількість слів, прочитанних дитиною.</p>
            <div className="frame-11">
                <div className="text-wrapper-28">2</div>
            </div>
        </div>
        <div className="frame-13">
            <div className="text-wrapper-27">Перевірте результат.</div>
            <div className="frame-11">
                <div className="text-wrapper-28">3</div>
            </div>
        </div>
    </Fragment>
);

const TechniqueReading = () => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [start, setStart] = useState(false);
    const [endReading, setEndReading] = useState(false);
    const [age, setAge] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (endReading) {
            setMessage(t('reading.message'));
        }
    }, [endReading]);

    return (
        <div className="view-5">
            <div className="frame-9">
                <Description/>
                <Text
                    start={start}
                    setStart={setStart}
                    age={age}
                    endReading={endReading}
                    setEndReading={setEndReading}
                />
                <SelectionAges setAge={setAge}/>
            </div>
            <Clockwise
                start={start}
                setStart={setStart}
                age={age}
                setEndReading={setEndReading}
                setAge={setAge}
            />
            <div className="message-after-reading">
                <Snackbar //показ сообщений об успехе чтения
                    open={!!message}
                    autoHideDuration={showMessageAfterRead}
                    onClose={() => setMessage('')}
                    message={message}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
                    ContentProps={{
                        className: classes.success
                    }}
                >
                    <MuiAlert severity={successMessageKey} onClose={() => setMessage('')}>{message}</MuiAlert>
                </Snackbar>
            </div>
        </div>
    );
};

export default TechniqueReading;