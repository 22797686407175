import {BasicCircleCheckedWrapper} from '../../../../components/BasicCircleCheckedWrapper';
import {BasicCircleChecked} from '../../../../icons/BasicCircleChecked';
import React from 'react';
import IconButton from '@mui/material/IconButton';

const AboutUs = () => {
    return (
        <div className="frame-20" id="about">
            <div className="overlap-8">
                <div className="frame-21">
                    <p className="div-5">
                        <span className="text-wrapper">Що таке </span>
                        <span className="span">школа розумного читання?</span>
                    </p>
                    <div className="flexcontainer-2">
                        <p className="text-2">
                  <span className="text-wrapper">
                    Якщо твій Супергерой або Супергеройка повільно читає, має проблеми з розумінням текстів,
                    переказуванням, узагальненням та паралельно не встигає у навчанні з інших предметів, тоді
                    приєднуйся до мого авторського курсу.
                    <br />
                  </span>
                        </p>
                        <p className="text-2">
                  <span className="text-wrapper">
                    Ми разом будемо ломати стереотипи старої школи, ніяких #мамамилараму, тільки{" "}
                  </span>
                            <span className="span">
                                <IconButton
                                        component="a"
                                        href="https://store.ababahalamaha.com.ua/?gclid=CjwKCAjw6p-oBhAYEiwAgg2PgvaHKVFwXIdJ7_CRQ37Hw0_l-TRe9cSOazl9dG3SlJQTaKwo0kFX-BoCbWEQAvD_BwE"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                    <img className='img-abalamaga' src='img/abalamaga.png'/>
                                </IconButton>
                            </span>
                            <span className="text-wrapper"> :) </span>
                        </p>
                    </div>
                    <div className="frame-22">
                        <div className="frame-23">
                            <div className="text-wrapper-45">
                                <p>Розвиток читацької грамотності за текстами PISA -</p>
                                <p>захопливі поєднання практичних завдань.</p>
                            </div>
                            <BasicCircleCheckedWrapper className="basic-circle-checked" />
                        </div>
                        <div className="frame-24">
                            <p className="text-wrapper-46">Читання без нудьги, найкращі та найсучасніші тексти.</p>
                            <BasicCircleChecked className="basic-circle-checked-instance" />
                        </div>
                        <div className="frame-25">
                            <div className="text-wrapper-46">Цікаві #квестичеленджі.</div>
                            <BasicCircleChecked className="basic-circle-checked-instance" />
                        </div>
                        <div className="frame-26">
                            <div className="text-wrapper-46">Вміння аргументовано сперечатися (дебати).</div>
                            <BasicCircleChecked className="basic-circle-checked-2" />
                        </div>
                        <div className="frame-27">
                            <p className="text-wrapper-47">
                                Cуспільне обговорення прочитанного та формування власної думки дитини.
                            </p>
                            <BasicCircleChecked className="basic-circle-checked-3" />
                        </div>
                        <div className="text-wrapper-48">Пропоную:</div>
                    </div>
                    <img className="rectangle-7" alt="Rectangle" src="/img/rectangle-45.png" />
                    <img className="rectangle-8" alt="Rectangle" src="/img/rectangle-44.png" />
                    <img className="rectangle-9" alt="Rectangle" src="/img/rectangle-46-1.png" />
                </div>
            </div>
        </div>
    );
};

export default AboutUs;