import React from 'react';

const Email = () => {
    return (
        <div className="frame-7">
            <img className="image-3" alt="Image" src="/img/image.svg" />
            <div className="text-wrapper-18">olga@pidustschool.com.ua</div>
        </div>
    );
};

export default Email;