import React from "react";

export const BasicCircleChecked = ({ className }) => {
  return (
    <svg
      className={`basic-circle-checked ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM20.1818 12C20.1818 16.5187 16.5187 20.1818 12 20.1818C7.48131 20.1818 3.81818 16.5187 3.81818 12C3.81818 7.48131 7.48131 3.81818 12 3.81818C16.5187 3.81818 20.1818 7.48131 20.1818 12Z"
        fill="#00B871"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M10.5 13.2322L15.7929 7.93934L17.2071 9.35355L10.5 16.0607L6.79289 12.3536L8.20711 10.9393L10.5 13.2322Z"
        fill="#00B871"
        fillRule="evenodd"
      />
    </svg>
  );
};
