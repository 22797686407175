import React from 'react';

const MobileLogo = () => {
    return (
        <div className="overlap-2">
            <div className="overlap-3">
                <img className="vector" alt="Vector" src="/mobile-img/vector-41.svg" />
                <img className="img" alt="Vector" src="/mobile-img/vector-42.svg" />
                <div className="text-wrapper">Школа</div>
                <div className="text-wrapper-2">Розумного Читання</div>
            </div>
            <div className="text-wrapper-3">Ольги Підуст</div>
        </div>
    );
};

export default MobileLogo;