import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
    return (
        <Link to="/">
        <div className="overlap-6">
            <div className="overlap-group-3">
                <img className="vector-3" alt="Vector" src="/img/vector-41-1.svg"/>
                <img className="vector-4" alt="Vector" src="/img/vector-42-1.svg"/>
                <div className="text-wrapper-20">Школа</div>
                <div className="text-wrapper-21">Розумного Читання</div>
            </div>
            <div className="text-wrapper-22">Ольги Підуст</div>
        </div>
        </Link>
    );
};

export default Logo;