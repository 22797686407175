import React from "react";

export const BasicPhone1 = ({ className }) => {
  return (
    <svg
      className={`basic-phone-1 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M23.6095 16.3907C23.0881 15.8693 22.2455 15.8693 21.7241 16.3907L19.5988 18.516C18.6135 18.2227 16.7748 17.556 15.6095 16.3907C14.4441 15.2253 13.7775 13.3867 13.4841 12.4013L15.6095 10.276C16.1308 9.75465 16.1308 8.91199 15.6095 8.39065L10.2761 3.05732C9.75479 2.53599 8.91212 2.53599 8.39079 3.05732L4.77479 6.67332C4.26812 7.17999 3.98279 7.87599 3.99345 8.58665C4.02412 10.4853 4.52679 17.08 9.72412 22.2773C14.9215 27.4747 21.5161 27.976 23.4161 28.008C23.4228 28.008 23.4468 28.008 23.4535 28.008C24.1575 28.008 24.8228 27.7307 25.3268 27.2267L28.9428 23.6107C29.4641 23.0893 29.4641 22.2467 28.9428 21.7253L23.6095 16.3907ZM23.4401 25.34C21.7761 25.312 16.0828 24.8653 11.6095 20.3907C7.12145 15.9027 6.68679 10.1893 6.66012 8.55865L9.33345 5.88532L12.7815 9.33332L11.0575 11.0573C10.7388 11.3747 10.6028 11.8333 10.6948 12.2733C10.7268 12.4267 11.5095 16.0627 13.7228 18.276C15.9361 20.4893 19.5721 21.272 19.7255 21.304C20.1695 21.3987 20.6241 21.2613 20.9415 20.9427L22.6668 19.2187L26.1148 22.6667L23.4401 25.34Z"
        fill="white"
      />
    </svg>
  );
};
