import React from 'react';
import {BrandFacebook1} from '../../../icons/BrandFacebook1';
import IconButton from '@mui/material/IconButton';

const Facebook = ({ className }) => {
    return (
        <div>
            <IconButton
                component="a"
                href="https://www.facebook.com/profile.php?id=100005769438352" // сылка на Facebook
                className={className}
                target="_blank"
                rel="noopener noreferrer"
            >
                <BrandFacebook1 />
            </IconButton>
        </div>
    );
};

export default Facebook;