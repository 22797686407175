import {BrandFacebook1} from '../../icons/BrandFacebook1';
import React from 'react';
import IconButton from '@mui/material/IconButton';

const BrandFacebookMenu = () => (
    <img alt="Brand instagram" src="mobile-img/mobile-menu-facebook.png"/>
);

const Facebook = ({className, mode}) => {
    return (
        <div>
            <IconButton
                component="a"
                href="https://www.facebook.com/profile.php?id=100005769438352" // сылка на Facebook
                className={className}
                target="_blank"
                rel="noopener noreferrer"
            >
                {mode === "menu" ? <BrandFacebookMenu /> : <BrandFacebook1 />}
            </IconButton>
        </div>
    );
};

export  default Facebook;