import {Button} from '@mui/material';
import React, {useState} from 'react';

const SelectionAgeButton = ({
                                child,
                                setAge,
                                years,
                                activeButton,
                                setActiveButton,
                            }) => {
    const handleButtonClick = () => {
        setActiveButton(() => years);
        setAge(() => years);
    };

    return (
        <Button
            variant={"contained"}
            key={years}
            onClick={handleButtonClick}
            sx={{
                backgroundColor: activeButton === years ? '#007bff' : '#ffffff',
                borderRadius: '8px',
                '&:hover': {
                    backgroundColor: '#00b871',
                },
                '&:active': {
                    backgroundColor: '#00b871',
                },
                textTransform: 'none',
            }}
        >
            {child}
        </Button>
    )
};

const MobileSelectionAges = ({setAge}) => {
    const [activeButton, setActiveButton] = useState('');
    return (
        <div className="frame-25">
            <div className="frame-26">
                <SelectionAgeButton
                    setAge={setAge}
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                    years={8}
                    child={
                        <p className="text-wrapper-46">8 років - 50-60 сл/хв</p>
                    }
                />
            </div>
            <div className="frame-27">
                <SelectionAgeButton
                    setAge={setAge}
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                    years={9}
                    child={
                        <p className="text-wrapper-46">9 років - 61-70 сл/хв</p>
                    }
                />
            </div>
            <div className="frame-28">
                <SelectionAgeButton
                    setAge={setAge}
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                    years={10}
                    child={
                        <p className="text-wrapper-46">10 років - 71- 80 сл/хв</p>
                    }
                />
            </div>
            <p className="text-wrapper-47">Орієнтовні показники темпу читання вголос:</p>
            <div className="frame-29">
                <SelectionAgeButton
                    setAge={setAge}
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                    years={11}
                    child={
                        <p className="text-wrapper-46">11 років - 81-95 сл/хв</p>
                    }
                />
            </div>
            <div className="frame-30">
                <SelectionAgeButton
                    setAge={setAge}
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                    years={12}
                    child={
                        <p className="text-wrapper-46">12 років - 100 сл/хв і вище</p>
                    }
                />
            </div>
        </div>
    );
};

export default MobileSelectionAges;