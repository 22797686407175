import React from 'react';
import { Link } from 'react-router-dom';

const LogoMenu = ({isNews = false}) => {
    const fieldStyle = isNews ? 'news' : 'main';
    const style = {
     main: {
         div1: "menu-view",
         div2: "menu-overlap",
         div3: "menu-overlap-group",
         img1: "menu-vector",
         img2: "menu-img",
         div4: "menu-text-wrapper-4",
         div5: "menu-text-wrapper-5",
         div6: "menu-text-wrapper-6",
     },
     news: {
         div1: "logo-news-view",
         div2: "logo-news-overlap",
         div3: "logo-news-overlap-group",
         img1: "logo-news-vector",
         img2: "logo-news-img",
         div4: "logo-news-text-wrapper-4",
         div5: "logo-news-text-wrapper-5",
         div6: "logo-news-text-wrapper-6",
     }
    };

    return (
        <Link to="/">
            <div className={style[fieldStyle].div1}>
                <div className={style[fieldStyle].div2}>
                    <div className={style[fieldStyle].div3}>
                        <img className={style[fieldStyle].img1} alt="Vector" src="/mobile-img/mobile-vector-41.svg"/>
                        <img className={style[fieldStyle].img2} alt="Vector" src="/mobile-img/mobile-vector-42.svg"/>
                        <div className={style[fieldStyle].div4}>Школа</div>
                        <div className={style[fieldStyle].div5}>Розумного Читання</div>
                    </div>
                    <div className={style[fieldStyle].div6}>Ольги Підуст</div>
                </div>
            </div>
        </Link>
    );
};

export default LogoMenu;