import React from 'react';

const WhatGiveMeCourse = () => {
    return (
        <div className="frame-28">
            <div className="overlap-9">
                <p className="div-6">
                    <span className="text-wrapper">Чому я </span>
                    <span className="span">навчусь</span>
                    <span className="text-wrapper"> у школі розумного читання</span>
                </p>
                <div className="frame-29">
                    <div className="frame-30">
                        <p className="div-7">
                            <span className="text-wrapper-49">Використовувати інтонації</span>
                            <span className="text-wrapper-50">
                    , робити голос сильним
                    <br /> та оксамитовим
                  </span>
                        </p>
                        <div className="img-wrapper">
                            <img className="img-2" alt="Tilda icons" src="/img/tilda-icons-11mu-melomane.svg" />
                        </div>
                    </div>
                    <div className="frame-31">
                        <p className="div-8">
                            <span className="text-wrapper">&nbsp;</span>
                            <span className="text-wrapper-51">Опрацьовувати</span>
                            <span className="text-wrapper">
                    {" "}
                                велику кількість матеріалу <br />
                    за невеликий проміжок часу
                  </span>
                        </p>
                        <div className="img-wrapper">
                            <img className="img-2" alt="Tilda icons book" src="/img/tilda-icons-1ed-book.svg" />
                        </div>
                    </div>
                    <div className="frame-32">
                        <p className="div-7">
                            <span className="text-wrapper-49">Говорити легко</span>
                            <span className="text-wrapper-50">
                    , виразно <br />і переконливо
                  </span>
                        </p>
                        <div className="img-wrapper">
                            <img className="img-2" alt="Tilda icons" src="/img/tilda-icons-1ed-speaker.svg" />
                        </div>
                    </div>
                    <div className="frame-33">
                        <p className="div-7">
                            <span className="text-wrapper-49">Аналізувати</span>
                            <span className="text-wrapper-50"> прочитаний текст, робити узагальнення, висновки,</span>
                        </p>
                        <div className="img-wrapper">
                            <img className="img-2" alt="Tilda icons" src="/img/tilda-icons-1ed-brain.svg" />
                        </div>
                    </div>
                    <div className="frame-logic-d">
                        <p className="div-7">
                            <span className="text-wrapper-49">Логічно мислити</span>
                        </p>
                        <div className="img-wrapper">
                            <img className="img-2" alt="Tilda icons" src="/mobile-img/quest_logic.svg" />
                        </div>
                    </div>
                    <div className="frame-success-d">
                        <p className="div-7">
                            <span className="text-wrapper-49">Бути успішною людиною</span>
                        </p>
                        <div className="img-wrapper">
                            <img className="img-2" alt="Tilda icons" src="/mobile-img/cup.svg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhatGiveMeCourse;