import React from 'react';
import IconButton from '@mui/material/IconButton';

const Instagram = ({className}) => {
    return (
    <div>
        <IconButton
            component="a"
            href="https://www.instagram.com/reading_course_olga_pidust/" // сылка на Инстаграм
            className={className}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img className="brand-instagram-2" alt="Brand instagram" src="/img/brand-instagram.svg"/>
        </IconButton>
    </div>
    );
};

export default Instagram;