import React from 'react';
import IconButton from '@mui/material/IconButton';

const Instagram = ({className, src}) => {
    return (
    <div>
        <IconButton
            component="a"
            href="https://www.instagram.com/reading_course_olga_pidust/" // сылка на Инстаграм
            className={className.div}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img className={className.img} alt="Brand instagram" src={src}/>
        </IconButton>
    </div>
    );
};
 export default Instagram;