import React, {useEffect, useState} from 'react';
import { TextField, Button, Box } from '@mui/material';
import {validateMobile} from '../../../../components/functions';
import {chatId, chatToken} from '../../../../components/chat/chatConfig';
import io from 'socket.io-client';
import {socketIOConfig, configServerApiUrl} from '../../../../constants';

const MoreAboutCourse = ({isModalOpen, isMobileForm = false}) => {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [socketConnection, setSocketConnection] = useState(io);
    const [loading, setLoading] = useState(false);

    // установка сокет соединения
    useEffect(() => {
        const newSocketConnection = io(`${configServerApiUrl}`, socketIOConfig);
        setSocketConnection(newSocketConnection);
    }, []);

    const sendMessageToTelegram = () => {
        const sendMessageToTelegram = (message) => {
            socketConnection.emit('userMessage', message);
        };
        const messageData = {
            chatToken: chatToken,
            chatId: chatId,
            mobile,
            sender: name,
            receiver: 'bot',
            text: 'Чекаю на дзвінок!',
            date: Date.now()
        };

        sendMessageToTelegram(messageData);
    };

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        if (!isModalOpen && mobileError) return;
        sendMessageToTelegram();
        setName('');
        setMobile('');
        setTimeout(() => setLoading(false), 2000);
    };

    const styleForLabel = {
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'floralwhite', // Цвет активного label
        },
        '& .MuiFormLabel-root': {
            color: 'floralwhite', // Цвет неактивного label
        },
    };
    const styleText = {
        '& .MuiOutlinedInput-input': {
            color: 'floralwhite',  // Цвет текста
            zIndex: 9999, // поверх всего
            overflow: 'hidden',  // Скрывает текст, выходящий за границы
        },
    };

    const styleInput = {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '50px',
                borderWidth: '2px',
                borderColor: 'white',
                backgroundColor: 'cadetblue',
                marginRight: '50px',
            },
        },
    };

    ///////// определяем стили
    const stylesForName = (isModalOpen ? {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '50px',
                borderWidth: '2px',
                backgroundColor: 'cadetblue',
                top: '5px',
            },
        },
        ...styleText,
        ...styleForLabel,
    } : {
        ...styleInput,
        ...styleText,
        ...styleForLabel,
    });

    const stylesForPhone = (isModalOpen ? {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '50px',
                borderWidth: '2px',
                backgroundColor: 'cadetblue',
                top: '5px',
            },
        },
        ...styleText,
        ...styleForLabel,
    } : {
        ...styleInput,
        ...styleText,
        ...styleForLabel,
    });

    const stylesForSubmitButton = (isModalOpen && !isMobileForm ? {
        borderRadius: '50px',
        borderWidth: '2px',
        borderColor: 'white',
        backgroundColor: 'aliceblue',
        color: 'green',
        fontWeight: 400,
        height: 50,
        width: 200,
        top: '-50px',
        marginLeft: '330px'
    } : isModalOpen && isMobileForm ? {
        borderRadius: '50px',
        borderWidth: '2px',
        height: 50,
        width: 200,
        margin: '10px'
    } : {
        borderRadius: '50px',
        borderWidth: '2px',
        borderColor: 'white',
        backgroundColor: 'aliceblue',
        marginLeft: '430px',
        color: 'green',
        fontWeight: 600,
        height: 55,
        width: 200,
        top: 18,
    });

    return (
        <Box className="feedback" id="feedBack">
            <Box className="overlap-7">
                <Box className="text-wrapper-40">Дізнайтесь більше про наш курс</Box>
                <Box className="frame-19">
                    <Box className="overlap-group-5">
                        <Box className="group-9">
                        </Box>
                        <Box className="text-wrapper-41">
                            <TextField
                                label="Ім’я"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                sx={stylesForName}
                            />
                        </Box>
                        <Box className="text-wrapper-42">
                            <TextField
                                label="Телефон"
                                variant="outlined"
                                value={mobile}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setMobile(value);
                                    if (!validateMobile(value)) {
                                        setMobileError('Некоректний номер телефону');
                                    } else {
                                        setMobileError('');
                                    }
                                }}
                                sx={stylesForPhone}
                                error={!!mobileError}
                                helperText={mobileError}
                            />
                        </Box>
                        { isModalOpen ? (!!mobile && !mobileError &&
                            <Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    sx={stylesForSubmitButton}
                                >
                                    Чекаю дзвінка!
                                </Button>
                            </Box>
                        ) : (<Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    sx={stylesForSubmitButton}
                                    disabled={loading || !name || !mobile || mobileError}
                                >
                                    {loading ? 'Відправка' : 'Чекаю дзвінка!'}
                                </Button>
                            </Box>)
                        }
                    </Box>
                </Box>
                <Box className="text-wrapper-44">
                    Напишіть телефон, наш менеджер зателефонує Вам і допоможе підібрати курс для Вашої дитини.
                </Box>
            </Box>
        </Box>
    );
};

export default MoreAboutCourse;