import React, {Fragment} from 'react';
import Footer from '../footer/Footer';
import {Link as LinkForRouter} from 'react-router-dom';
import CallByPhone from '../header/callByPhone/CallByPhone';
import Facebook from '../../contacts/facebook';
import Instagram from '../../contacts/instagram';
import Logo from '../../logo/Logo';
import Telegram from '../../contacts/telegram';
import Blog from './blog/Blog';
import LogoMenu from '../../../Mobile/components/Menu/logoMenu';

const HeaderForNews = ({isMobile}) => {
    return (
        isMobile ? <LogoMenu isNews="true"/> :
        <div className="view-7">
            <div className="overlap-11">
                <div className="navbar">
                    <div className="text-wrapper-55">
                        <LinkForRouter to="/" smooth="true" duration={500}>Перейти на головну сторінку</LinkForRouter>
                    </div>
                </div>
                <CallByPhone/>
            </div>
            <Facebook className="brand-facebook-header" />
            <Instagram className="brand-instagram-header"/>
            <div className="view-8">
                <Logo/>
            </div>
            <Telegram className="brand-telegram-header"/>
        </div>
    );
};

const News = ({isMobile}) => {
    return (
        <Fragment>
                <div className="desktop">
                    <HeaderForNews isMobile={isMobile}/>
                    {/*<Footer/>*/}
                    {/*<p className="text-wrapper-25">© 2023 - PidustSchool. All Rights Reserved.</p>*/}
                </div>
            <Blog/>
        </Fragment>
    );
};

export default News;