import React from 'react';

const MobileReviews = () => {
    return (
        <div className="revies">
            <div className="group-2">
                <div className="group-wrapper">
                    <div className="group-3">
                        <div className="overlap-group-wrapper">
                            <div className="overlap-group-2">
                                <p className="text-wrapper-54">
                                    Дуже класні курси! Мій син пройшов ці курси – результати вражаючі навіть за короткий проміжок
                                    часу!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="image-3" alt="Image" src="/mobile-img/2x.png" />
            </div>
            <div className="text-wrapper-55">Марина Анатольевна</div>
            <div className="hr-dots">
                <div className="ellipse" />
                <div className="ellipse-2" />
                <div className="ellipse-3" />
            </div>
            <div className="text-wrapper-56">Відгуки</div>
        </div>
    );
};

export default MobileReviews;