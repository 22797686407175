import React from "react";
import "./menu.css";
import Contacts from '../Contacts/Contacts';
import {Link} from 'react-scroll';
import {Link as LinkForRouter} from 'react-router-dom';
import LogoMenu from './logoMenu';

export const Menu = ({menuOpen, setMenuOpen}) => {

  const handleMenuClose = () => {
    setMenuOpen(() => false);
  }

  return ( menuOpen &&
    <div className="menu-mobile">
      <div className="menu-div">
        <div className="menu-text-wrapper">
          <Link to="mobileTeachers" smooth="true" duration={500}>Викладачі</Link>
        </div>
        <div className="menu-text-wrapper-2">
          <Link to="mobileAbout" smooth="true" duration={500}>Про нас</Link>
        </div>
        <div className="menu-text-wrapper-3">
          <Link to="mobile-about-course" smooth="true" duration={500}>Про курс</Link>
        </div>
        <img className="menu-ep-close" alt="Ep close" src="/mobile-img/mobile-ep-close.svg" onClick={handleMenuClose}/>
        <LogoMenu/>
        <Contacts mode={"menu"}/>
        <div className="mobile-news">
          <LinkForRouter to="/news" smooth="true" duration={500}>Новини</LinkForRouter>
        </div>
      </div>
    </div>
  );
};
