import React from 'react';

const Email = ({className}) => {
    return (
        <div className={className.div}>
            <img className="image-2" alt="Image" src="/mobile-img/image-2.svg" />
            <div className={className.div2}>olga@pidustschool.com.ua</div>
        </div>
    );
};

export default Email;