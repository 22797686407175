import React from 'react';
import './Blog.css';
import AddNews from '../AddNews';
import ReadNews from './ReadNews';

const Blog = () => {
    return (
        <div>
            <div className="news">
                <span className="news-header">"Новини про нас"</span>
            </div>
            <AddNews/>
            <ReadNews/>
        </div>
    );
};

export default Blog;