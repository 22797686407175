import React from "react";
import "./style.css";

export const BasicCircleCheckedWrapper = ({ className }) => {
  return (
    <img
      className={`basic-circle-checked-wrapper ${className}`}
      alt="Basic circle checked"
      src="/img/basic-circle-checked-4.png"
    />
  );
};
