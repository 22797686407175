import {BasicClock} from '../../../../icons/BasicClock';
import {BasicHappy} from '../../../../icons/BasicHappy';
import {CalendarCalendar} from '../../../../icons/CalendarCalendar';
import React from 'react';


const AboutCourse = () => {
    return (
        <div className="view" id="about-course">
            <div className="overlap-group">
                <div className="rectangle" />
                <div className="view-2" />
                <p className="p">
                    <span className="text-wrapper">Про</span>
                    <span className="span"> курс</span>
                </p>
                <div className="frame">
                    <img className="img" alt="Rectangle" src="/img/rectangle-50.png" />
                    <div className="view-3">
                        <div className="text-wrapper-2">Онлайн курс</div>
                        <div className="group">
                            <div className="div-wrapper">
                                <div className="text-wrapper-3">8-12 років</div>
                            </div>
                        </div>
                        <div className="text-wrapper-4">«Курс Швидкочитання (Advanced)»</div>
                        <div className="frame-2">
                            <BasicClock className="icon-instance-node-course" />
                            <p className="text-wrapper-5">21 заняття з кваліфікованим педагогом.</p>
                        </div>
                        <div className="frame-3">
                            <BasicHappy className="icon-instance-node-course" />
                            <p className="text-wrapper-5">У групі по 3-5 учнів або індивідуально.</p>
                        </div>
                        <div className="frame-4">
                            <CalendarCalendar className="icon-instance-node-course" />
                            <div className="text-wrapper-6">Графік навчання:</div>
                            <div className="text-wrapper-7">2 рази на тиждень.</div>
                        </div>
                        <p className="text-wrapper-8">
                            Для дітей, у яких техніка читання є не нижчою, ніж 60 слів за 1 хвилину.
                        </p>
                        <p className="text-wrapper-9">
                            На цьому курсі дитина зможе підвищити рівень читацьких навичок, зможе опрацьовувати велику кількість
                            матеріалу за невеликий проміжок часу, а головне - розуміти суть, аналізувати будь-який текст,
                            переказувати, робити узагальнення, висновки.
                        </p>
                        <p className="text-wrapper-group">
                            Залежно від умінь та віку дитини, курс об’єднується у 2 окремі групи: 8-10 років та 10-12 років.
                        </p>
                    </div>
                    <p className="text-wrapper-10">Всього 110 грн. за 1 заняття</p>
                    <p className="element">
                        <span className="text-wrapper-coast">Вартість курсу групових занять:&nbsp;&nbsp;</span>
                        <span className="text-wrapper-decoration">
                            <span className="text-decoration">4000 грн.</span>
                        </span>
                        <span className="text-wrapper-12">&nbsp;&nbsp;</span>
                        <span className="text-wrapper-11">2300 грн.</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AboutCourse;