import React from 'react';
import CallBackOrder from '../../../Desktop/baseComponents/callBackOrder/CallBackOrder';

const CallOrder = ({className}) => {

    return (
        <CallBackOrder className={className} isMobileForm="true"/>
    );
};

export default CallOrder;