import React from 'react';
import "./style.css";

export const MenuHamburger = ({ className, setMenuOpen }) => {
  const handleOpenMenu = () => {
      setMenuOpen(() => true)
  };

  return <img
            className={`menu-hamburger ${className}`}
            alt="Menu hamburger"
            src="/mobile-img/menu-hamburger.png"
            onClick={handleOpenMenu}
          />
};
