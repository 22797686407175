import React, { useState } from "react";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const NewsList = ({ allNews }) => {
    const [expandedNews, setExpandedNews] = useState(null);

    const toggleExpand = (index) => {
        if (expandedNews === index) {
            setExpandedNews(null);
        } else {
            setExpandedNews(index);
        }
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'floralwhite',
            marginTop: '50px'
        }}>
            {allNews.map((news, index) => (
                <div key={news.created_at} style={{ margin: '10px 0', textAlign: 'center', width: 'auto' }}>
                    <h2 onClick={() => toggleExpand(index)}>
                        <span
                            dangerouslySetInnerHTML={{ __html: news.title }}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                color: 'var(--x-1)',
                                fontSize: '20px',
                                fontWeight: '600',
                                maxWidth: 'auto'
                            }}
                        ></span>
                        <IconButton size="small" onClick={() => toggleExpand(index)} style={{ marginLeft: '10px' }}>
                            {expandedNews === index ? <RemoveIcon /> : <AddIcon />}
                        </IconButton>
                    </h2>
                    {expandedNews === index &&
                    <div
                        dangerouslySetInnerHTML={{ __html: news.description }}
                        style={{
                            color: 'black',
                            fontSize: '18px',
                            fontWeight: '400',
                            maxWidth: 'auto'
                        }}
                    ></div>}
                </div>
            ))}
        </div>
    );
}

export default NewsList;
