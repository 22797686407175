import React, {useEffect, useState} from 'react';
import {Box, Button, TextField} from '@mui/material';
import {validateMobile} from '../../../../components/functions';
import io from 'socket.io-client';
import {chatId, chatToken} from '../../../../components/chat/chatConfig';
import {socketIOConfig, configServerApiUrl} from '../../../../constants';

const WaitCall = () => {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [socketConnection, setSocketConnection] = useState(io);
    const [loading, setLoading] = useState(false);

    // установка сокет соединения
    useEffect(() => {
        const newSocketConnection = io(`${configServerApiUrl}`, socketIOConfig);
        setSocketConnection(newSocketConnection);
    }, []);

    const sendMessageToTelegram = () => {
        const sendMessageToTelegram = (message) => {
            socketConnection.emit('userMessage', message);
        };
        const messageData = {
            chatToken: chatToken,
            chatId: chatId,
            mobile,
            sender: name,
            receiver: 'bot',
            text: 'Чекаю на дзвінок!',
            date: Date.now()
        };

        sendMessageToTelegram(messageData);
    };

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        if (mobileError) return;
        sendMessageToTelegram();
        setName('');
        setMobile('');
        setTimeout(() => setLoading(false), 2000);
    };

    const styleForLabel = {
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'floralwhite', // Цвет активного label
        },
        '& .MuiFormLabel-root': {
            color: 'floralwhite', // Цвет неактивного label
        },
    };
    const styleText = {
        '& .MuiOutlinedInput-input': {
            color: 'floralwhite',  // Цвет текста
            zIndex: 9999, // поверх всего
            overflow: 'hidden',  // Скрывает текст, выходящий за границы
        },
        left: 80,
        top: 220,
    };

    const styleInput = {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '50px',
                borderWidth: '2px',
                borderColor: 'white',
                backgroundColor: 'cadetblue',
                marginRight: '50px',
                backgroundSize: '100% 100%',
                height: 48,
                position: 'absolute',
                width: 200,
            },
        },
    };

    ///////// определяем стили
    const stylesForName = {
        ...styleInput,
        ...styleText,
        ...styleForLabel,
    };

    const stylesForPhone = {
        ...styleInput,
        ...styleText,
        ...styleForLabel,
    };

    const stylesForSubmitButton = {
        borderRadius: '44px',
        borderWidth: '2px',
        borderColor: 'white',
        backgroundColor: 'aliceblue',
        marginLeft: '80px',
        color: 'green',
        fontWeight: 600,
        height: 48,
        width: 200,
        top: 359,
        boxSizing: 'border-box',
        position: 'absolute',
    };

    return (
        <div className="feedback">
            <Box className="name">
            <TextField
                label="Ім’я"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={stylesForName}
            />
            </Box>
            <Box className="phone">
            <TextField
                label="Телефон"
                variant="outlined"
                value={mobile}
                onChange={(e) => {
                    const value = e.target.value;
                    setMobile(value);
                    if (!validateMobile(value)) {
                        setMobileError('Некоректний номер телефону');
                    } else {
                        setMobileError('');
                    }
                }}
                sx={stylesForPhone}
                error={!!mobileError}
                helperText={mobileError}
            />
            </Box>
            <Box className="button-wait-call">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={stylesForSubmitButton}
                    disabled={loading || !name || !mobile || mobileError}
                >
                    {loading ? 'Відправка' : 'Чекаю дзвінка!'}
                </Button>
            </Box>
            <p className="text-wrapper-32">
                Напишіть телефон, наш менеджер зателефонує Вам і допоможе підібрати курс для Вашої дитини.
            </p>
            <p className="text-wrapper-33">Дізнайтесь більше про наш курс</p>
        </div>
    );
};

export default WaitCall;