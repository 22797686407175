import {BasicCircleChecked} from '../../icons/BasicCircleChecked';
import React from 'react';
import IconButton from '@mui/material/IconButton';

const WhatIsSchool = () => {
    return (
        <div className="about" id="mobileAbout">
            <p className="div-2">
                <span className="span">Що таке </span>
                <span className="text-wrapper-4">школа розумного читання?</span>
            </p>
            <div className="flexcontainer">
                <p className="text">
              <span className="span">
                Якщо твій Супергерой або Супергеройка повільно читає, має проблеми з розумінням текстів,переказуванням,
                  узагальненням та паралельно не встигає у навчанні з інших предметів,
                  тоді приєднуйся
                до мого авторького курсу.
              </span>
                </p>
                <p className="text">
              <span className="span">
                Ми разом будемо ломати стереотипи старої школи, ніяких #мамамилараму, тільки
              </span>
                    <span className="text-wrapper-4">
                                <IconButton
                                    component="a"
                                    href="https://store.ababahalamaha.com.ua/?gclid=CjwKCAjw6p-oBhAYEiwAgg2PgvaHKVFwXIdJ7_CRQ37Hw0_l-TRe9cSOazl9dG3SlJQTaKwo0kFX-BoCbWEQAvD_BwE"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                 <img className='abalamaga' src='img/abalamaga.png'/>
                                </IconButton>
                    </span>
                    <span className="span"> :) </span>
                </p>
            </div>
            <div className="frame-2">
                <div className="frame-3">
                    <div className="text-wrapper-7-1">Розвиток читацької грамотності за текстами<br/>
                        PISA-захопливі поєднання практичних завдань.
                    </div>
                    <BasicCircleChecked className="icon-instance-node" />
                </div>
                <div className="frame-4">
                    <p className="text-wrapper-8">Читання без нудьги, найкращі та найсучасніші тексти.</p>
                    <BasicCircleChecked className="icon-instance-node" />
                </div>
                <div className="frame-5">
                    <div className="text-wrapper-7">Цікаві #квестичеленджі.</div>
                    <BasicCircleChecked className="icon-instance-node" />
                </div>
                <div className="frame-6">
                    <div className="text-wrapper-9">Вміння аргументовано сперечатися (дебати).</div>
                    <BasicCircleChecked className="icon-instance-node" />
                </div>
                <div className="frame-7">
                    <p className="text-wrapper-9">Cуспільне обговорення прочитанного та формування власної думки дитини.</p>
                    <BasicCircleChecked className="icon-instance-node" />
                </div>
                <div className="text-wrapper-10">Пропоную:</div>
            </div>
            <div className="frame-8">
                <img className="rectangle" alt="Rectangle" src="/mobile-img/rectangle-45.png" />
                <img className="rectangle-2" alt="Rectangle" src="/mobile-img/rectangle-44.png" />
                <img className="rectangle-3" alt="Rectangle" src="/mobile-img/rectangle-46-1.png" />
            </div>
        </div>
    );
};

export default WhatIsSchool;