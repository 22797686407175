import React from 'react';
import { Link } from 'react-scroll';
import {Link as LinkForRouter} from 'react-router-dom';
import Logo from '../../logo/Logo';
import Facebook from '../../contacts/facebook';
import Instagram from '../../contacts/instagram';
import Telegram from '../../contacts/telegram';
import CallByPhone from './callByPhone/CallByPhone';

const NavbarLinks = ({setIsZoomed = () => {}}) => {
    const handleLinkClick = (targetId, duration = 500) => {
        setIsZoomed(() => true);

        const scrollOptions = {
            smooth: "true",
            duration
        };
        if (targetId) {
            document.getElementById(targetId).scrollIntoView(scrollOptions);
        }
    };

    return (
        <div className="navbar">
            <div className="text-wrapper-55">
                <Link to="about" smooth="true" onClick={() => handleLinkClick("about")}>
                    Про нас
                </Link>
            </div>
            <div className="text-wrapper-56">
                <Link to="teachers" smooth="true" onClick={() => handleLinkClick("teachers")}>
                    Викладачі
                </Link>
            </div>
            <div className="text-wrapper-57">
                <Link to="about-course" smooth="true" onClick={() => handleLinkClick("about-course")}>
                    Про курс
                </Link>
            </div>
            <div className="text-wrapper-58">
                <Link to="contacts" smooth="true" onClick={() => handleLinkClick("contacts")}>
                    Контакти
                </Link>
            </div>
            <div className="text-wrapper-60">
                <LinkForRouter to="/news" smooth="true" duration={500}>
                    Новини
                </LinkForRouter>
            </div>
        </div>
    );
};

const Header = ({setIsZoomed}) => {
   return (
       <div className="view-7">
           <div className="overlap-11">
               <NavbarLinks setIsZoomed={setIsZoomed}/>
               <CallByPhone/>
           </div>
          <Facebook className="brand-facebook-header" />
          <Instagram className="brand-instagram-header"/>
          <img className="group-11" alt="Group" src="/img/group-2638.png" />
          <div className="view-8">
              <Logo/>
          </div>
           <Telegram className="brand-telegram-header"/>
       </div>
   )
};

export default Header;