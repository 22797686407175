import React from "react";

export const BrandFacebook1 = ({ className }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_16_149)">
        <circle className="circle" cx="22" cy="22" fill="white" r="22" />
        <path
          className="path"
          d="M22 0C9.85039 0 0 9.90985 0 22.1328C0 33.1782 8.04401 42.3332 18.5633 44V28.532H12.9747V22.1328H18.5633V17.2565C18.5633 11.705 21.8482 8.64373 26.8713 8.64373C29.2783 8.64373 31.7998 9.07536 31.7998 9.07536V14.5184H29.0187C26.2904 14.5184 25.4367 16.2272 25.4367 17.9781V22.1284H31.5336L30.5589 28.5276H25.4367V43.9956C35.956 42.3377 44 33.1804 44 22.1328C44 9.90985 34.1496 0 22 0Z"
          fill="#00B871"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_16_149">
          <rect className="rect" fill="white" height="44" width="44" />
        </clipPath>
      </defs>
    </svg>
  );
};
