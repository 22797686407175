import {MenuHamburger} from '../components/MenuHamburger';
import React from 'react';
import "./style.css";
import MobileLogo from '../components/mobileLogo/MobileLogo';
import SmartSchool from '../components/SmartSchool/SmartSchool';
import MobileReviews from '../components/reviews/MobileReviews';
import Footer from '../components/footer/Footer';
import {Menu} from '../components/Menu/Menu';
import WhatIsSchool from '../components/WhatIsSchool/WhatIsSchool';
import WhatIsLearning from '../components/WhatIsLearning/WhatIsLearning';
import AboutCourse from '../components/AboutCourse/AboutCourse';
import WaitCall from '../components/WaitCall/WaitCall';
import AboutAuthor from '../components/AboutAuthor/AboutAuthor';
import TechnicRead from '../components/TechnicRead/TechnicRead';

const Main = ({menuOpen, setMenuOpen}) => {

    return (
        <div className="mobile">
            <div className="div">
                <div className="overlap">
                    <div className="frame">
                        <div className="overlap-group">
                            <img className="star" alt="Star" src="/mobile-img/star-3.svg" />
                        </div>
                    </div>
                    <div className="view">
                     <MobileLogo/>
                    </div>
                    {!menuOpen && <MenuHamburger className="menu-hamburger-instance" setMenuOpen={setMenuOpen}/>}
                    {!menuOpen && <SmartSchool/>}
                    {menuOpen && <Menu
                                    menuOpen={menuOpen}
                                    setMenuOpen={setMenuOpen}
                                />}
                </div>
                 <WhatIsSchool/>
                <WhatIsLearning/>
                <AboutCourse/>
                <WaitCall isMobileForm="true"/>
                <AboutAuthor/>
                <TechnicRead/>
                <div className="revies-footer">
                    <Footer/>
                    <MobileReviews/>
                </div>
            </div>
        </div>
    );
};

export default Main;