import React from 'react';

const Phone = () => {
    return (
        <div className="frame-6">
            <img className="image-2" alt="Image" src="/img/1.svg" />
            <a  className="text-wrapper-17"
                href="tel:+380987542599"
            >(098) 754 25 99</a>
        </div>
    );
};

export default Phone;