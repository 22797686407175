import Header from '../header/Header';
import React from 'react';
import CallBackOrder from '../callBackOrder/CallBackOrder';


const What = ({setIsZoomed}) => {
  return (
      <div className="main-screen">
          <div className="overlap-10">
              <img className="image-4" alt="Image" src="/img/image.png" />
              <CallBackOrder className={{button: "button-3", div: "text-wrapper-53"}}/>
              <p className="div-9">
              <span className="text-wrapper">Школа <br /></span>
                  <span className="span">розумного читання</span><br />
                  <span className="text-wrapper">Ольги Підуст</span>
              </p>
              <p className="text-wrapper-54">Курс швидкочитання для дітей віком 8-12 років</p>
              <Header setIsZoomed={setIsZoomed}/>
              <img className="vector-6" alt="Vector" src="/img/vector-40.svg" />
              <img className="group-13" alt="Group" src="/img/group-2639.png" />
              <img className="star" alt="Star" src="/img/star-1.svg" />
              <img className="star-2" alt="Star" src="/img/star-3.svg" />
              <img className="star-3" alt="Star" src="/img/star-4.svg" />
              <div className="ellipse-9" />
              <div className="ellipse-10" />
              <div className="ellipse-11" />
              <img className="vector-7" alt="Vector" src="/img/vector-38.svg" />
              <div className="group-14">
                  <div className="overlap-12">
                      <div className="rectangle-10" />
                      <img className="happy" alt="Happy" src="/img/happy1-2.png" />
                  </div>
              </div>
          </div>
      </div>
      );
};

export default What;
