import {BasicClock} from '../../../../icons/BasicClock';
import {BasicHeartOutline} from '../../../../icons/BasicHeartOutline';
import React from 'react';

const AuthorOfCourse = () => {
    return (
        <div className="view-6" id="teachers">
            <div className="frame-16">
                <div className="text-wrapper-35">Ольга Підуст</div>
                <div className="frame-17">
                    <p className="text-wrapper-36">Досвід роботи більше 12 років.</p>
                    <BasicClock className="icon-instance-node" />
                </div>
                <p className="text-wrapper-37">Авторка курсу, вчителька початкових класів, психологиня, мама.</p>
                <div className="frame-18">
                    <BasicHeartOutline className="icon-instance-node" />
                    <p className="text-wrapper-38">Вища психологічна та педагогічна освіта.</p>
                </div>
                <div className="flexcontainer">
                    <p className="text">
                  <span className="text-wrapper-39">
                    Маю досвід роботи вчителем у Szkoła Podstawowa Warszawa Polska, низку пройдених міжнародних освітніх
                    тренінгів, семінарів, конференцій, форумів.
                    <br />
                  </span>
                    </p>
                    <p className="text">
                  <span className="text-wrapper-39">
                    До кожного учня я знаходжу індивідуальний підхід,
                      роблю наші заняття максимально змістовними, корисними і цікавими.
                  </span>
                    </p>
                </div>
                <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-46.svg" />
            </div>
            <p className="div-4">
                <span className="span">Викладачка та авторка</span>
                <span className="text-wrapper"> курсу</span>
            </p>
        </div>
    );
};

export default AuthorOfCourse;