import React from 'react';
import Phone from './Phone';
import Email from './Email';
import Facebook from './Facebook';
import Instagram from './Instagram';
import Telegram from './Telegram';
import {Link} from 'react-scroll';
import PublicOfferAgreement from '../../../../components/PublicOfferAgreement/PublicOfferAgreement';

const Contacts = ({mode}) => {
    const style = mode === "footer" ? {
        frame: "frame-31",
        text: "text-wrapper-50",
        phone: {div: "frame-32", p: "text-wrapper-48"},
        email: {div: "frame-33", div2: "text-wrapper-49"},
        facebook: "brand-facebook",
        instagram: {div: "brand-instagram", img: "brand-instagram-2"},
        telegram: {div: "frame-wrapper", img: "frame-35"}
    } : {
        frame: "menu-frame",
        text: "menu-text-wrapper-8",
        phone: {div: "menu-frame-2", p: "menu-p"},
        email: {div: "menu-frame-3", div2: "menu-text-wrapper-7"},
        facebook: "menu-brand-facebook",
        instagram: {div: "menu-brand-instagram", img: "menu-brand-instagram-2"},
        telegram: {div: "menu-group-telegram", img: "menu-frame-telegram"}
    }

    return (
        <div className={style.frame} id="mobileContacts">
            <Phone className={style.phone}/>
            <Email className={style.email}/>
            <div className={style.text}>
                <Link to="mobileContacts" smooth="true" duration={500}>Контакти</Link>
            </div>
            {mode === 'footer' ? (
            <div className="frame-34">
                <Facebook className={style.facebook} mode={mode}/>
                <Instagram className={style.instagram} src={"/mobile-img/brand-instagram.svg"}/>
                <Telegram className={style.telegram} src={"/mobile-img/frame.svg"}/>
            </div>
            ) : (
                <div>
                    <Facebook className={style.facebook} mode={mode}/>
                    <Instagram className={style.instagram} src={"/mobile-img/mobile-brand-instagram.svg"}/>
                    <Telegram className={style.telegram} src={"/mobile-img/mobile-frame.svg"}/>
                </div>
            )}
        </div>
    );
};

export default Contacts;