import React from 'react';
import IconButton from '@mui/material/IconButton';

const Telegram = ({className, src}) => {
    return (
    <div>
        <IconButton
            component="a"
            href="https://t.me/+evURFqmHX60wMjQy" // сылка на телеграм канал
            className={className.div}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img className={className.img} alt="Frame" src={src}/>
        </IconButton>
    </div>
    );
};

export default Telegram;