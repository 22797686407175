export const fullDateFormatWithWeekDay = 'ccc LLL dd yyyy HH:mm:ss';
export const loginFieldKey = 'login';
export const passwordFieldKey = 'password';
export const adminNewsName = 'PidustSchool';
export const adminNewsPassword = 'PidustSchool1234567';
export const isOpenKey = 'isOpen';
export const isCloseKey = 'isClose';
export const successMessageKey = 'success';
export const errorMessageKey = 'error';
export const showMessageAfterRead = 20000;

export const socketIOConfig ={
    secretHeader: {
        "Access-Control-Allow-Headers": "*"
    },
    path: '/pidustschool_socket/',
    secure: true
};

export const GET = 'GET';
export const POST = 'POST';
export const fetchHeaders = {
    headers: {
        'Content-Type': 'application/json',
    },
};

const isLocal = false;
export const configServerApiUrl = isLocal ? 'http://localhost:3001' : 'https://pidustschool.com.ua';