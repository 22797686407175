import React, {Fragment, useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogTitle, TextField} from '@mui/material';
import './AddNews.css';
import {
    loginFieldKey,
    passwordFieldKey,
    adminNewsName,
    adminNewsPassword,
    isOpenKey,
    isCloseKey,
} from '../../../../constants';
import {useTranslation} from 'react-i18next';
import AddNewsDialog from './AddNewsDialog';

const check = {
  [loginFieldKey]: adminNewsName,
  [passwordFieldKey]: adminNewsPassword,
};

const AddNews = () => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(t(''));
    const [passwordError, setPasswordError] = useState(t(''));
    const [showAddNewsDialog, setShowAddNewsDialog] = useState(isCloseKey);

    const handleAddNews = () => {
        setIsOpen(!isOpen)
    };

    const handleValidation = (value, field) => {
        return check[field] === value;
    };

    const handleSubmit = () => {
        setIsOpen(!isOpen);
        setShowAddNewsDialog(isOpenKey);
    };

    return (
        <Fragment>
            <div className="add-news">
                <Button
                    onClick={handleAddNews}
                    className="add-news-button"
                    variant="outlined"
                    color="primary"
                >
                    {t('news.addNews')}
                </Button>
            </div>
            <Dialog // Перевірка логіну та паролю
                open={isOpen}
                onClose={handleAddNews}
            >
                <DialogTitle>
                    {t('news.login')}
                </DialogTitle>
                <DialogActions>
                    <form className="dialog-input-form">
                        <TextField
                           type="text"
                           value={login}
                           onChange={(e) => {
                              const value = e.target.value;
                              setLogin(value);
                              if (!handleValidation(value, loginFieldKey)) {
                                 setLoginError(t('news.error.login'));
                              } else {
                                setLoginError(t(''));
                              }
                           }}
                           fullWidth
                           variant="outlined"
                           placeholder={t('news.loginName')}
                           error={!!loginError}
                           helperText={loginError}
                        />
                        <TextField
                            type="text"
                            value={password}
                            onChange={(e) => {
                                const value = e.target.value;
                                setPassword(value);
                                if (!handleValidation(value, passwordFieldKey)) {
                                    setPasswordError(t('news.error.password'));
                                } else {
                                    setPasswordError(t(''));
                                }
                            }}
                            fullWidth
                            variant="outlined"
                            placeholder={t('news.loginPassword')}
                            error={!!passwordError}
                            helperText={passwordError}
                        />
                        {login && password && !loginError && !passwordError &&
                            <Button onClick={handleSubmit}
                                    variant="contained"
                                    color="primary"
                                    className="chat-submit-button"
                            >
                                {t('news.open')}
                            </Button>
                        }
                    </form>
                </DialogActions>
            </Dialog>
            <AddNewsDialog
                showAddNewsDialog={showAddNewsDialog}
                setShowAddNewsDialog={setShowAddNewsDialog}
            />
        </Fragment>
    );
};

export default AddNews;